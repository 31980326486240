import { Container, Stack, StyledEngineProvider, Typography, Unstable_Grid2 as Grid } from "@mui/material";

import BakktLogo from "../../assets/images/bakkt_logo.svg";
import styles from "./Footer.module.css";

export const Footer = () => {
  return (
    <StyledEngineProvider injectFirst>
      <Grid sx={{ backgroundColor: "primary.main", color: "primary.contrastText", py: 6, px: 4 }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" spacing={4}>
            <img src={BakktLogo} alt="Bakkt Logo" className={styles["footer_logo"]} />
            <Typography variant="body2">
              Copyright {new Date().getFullYear()} &copy; Bakkt Trust Company LLC. All rights reserved
            </Typography>
          </Stack>
        </Container>
      </Grid>
    </StyledEngineProvider>
  );
};
