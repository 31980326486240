import { Alert, Paper, Typography } from "@bakkt/bakkt-ui-components";
import { Container, Grid, Stack } from "@mui/material";
import React, { useMemo } from "react";
import {
  Await,
  defer,
  LoaderFunctionArgs,
  Outlet,
  useLoaderData,
  useParams,
  useRouteLoaderData,
} from "react-router-dom";

import LoadingIndicator from "../../components/loading/LoadingIndicator";
import { useRootContext } from "../../RootLayout";
import {
  accountHistories,
  fetchMockDataPromiseWithDelay,
  internalHistories,
  orgHistories,
} from "../../services/mockData";
import { Account, HistorySummary, Organization, User } from "../../services/openAPI/internal";
import { HistoryService } from "../../services/serviceLoader";
import { shouldUseMockData } from "../../utils/dataUtils";
import { innerGridContainer, subText, wrapperContainer } from "../dashboard/styles";
import { AuditLogsGrid } from "./AuditLogsGrid";

const AuditLogs = () => {
  const { accountHistoriesPromise, orgHistoriesPromise, internalHistoriesPromise } = useLoaderData() as {
    accountHistoriesPromise: Promise<HistorySummary[]>;
    orgHistoriesPromise: Promise<HistorySummary[]>;
    internalHistoriesPromise: Promise<HistorySummary[]>;
  };
  const {
    accounts,
    orgs: organizations,
    allUsers: users,
  } = useRouteLoaderData("root") as {
    accounts: Account[];
    orgs: Organization[];
    allUsers: User[];
  };

  const fetchHistoriesPromise = useMemo(
    () => Promise.all([accountHistoriesPromise, orgHistoriesPromise, internalHistoriesPromise]),
    [accountHistoriesPromise, orgHistoriesPromise, internalHistoriesPromise]
  );
  const { accountId, organizationId } = useParams();

  return (
    <>
      <Outlet context={useRootContext()} />
      <Container maxWidth="xl" disableGutters sx={wrapperContainer}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Typography variant="h3">Audit Log</Typography>
            <Typography variant="body1" sx={subText}>
              Showing all Audit Logs.
            </Typography>
          </Grid>
        </Grid>

        <Paper>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Container sx={innerGridContainer} data-testid="auditLogsTable">
                <React.Suspense
                  fallback={
                    <Stack sx={{ mt: 12, mb: 7 }}>
                      <LoadingIndicator />
                    </Stack>
                  }
                >
                  <Await
                    resolve={fetchHistoriesPromise}
                    errorElement={<Alert severity="error">Error loading audit log data!</Alert>}
                  >
                    {([accountHistories, orgHistories, internalHistories]) => (
                      <AuditLogsGrid
                        accountHistories={accountHistories}
                        orgHistories={orgHistories}
                        internalHistories={internalHistories}
                        accounts={accounts}
                        organizations={organizations}
                        users={users}
                      />
                    )}
                  </Await>
                </React.Suspense>
              </Container>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default AuditLogs;

export async function loader({ params }: LoaderFunctionArgs) {
  try {
    const accountId = Number(params.accountId);
    const orgId = Number(params.organizationId);

    //Prevent API call when no client and organization is selected
    const orgAccountSelected = accountId && orgId;

    let accountHistoriesPromise;
    let orgHistoriesPromise;
    let internalHistoriesPromise;

    if (orgAccountSelected) {
      accountHistoriesPromise = shouldUseMockData
        ? fetchMockDataPromiseWithDelay(accountHistories, 6000)
        : HistoryService.getInternalHistorySummaries(0, 10000, accountId);

      orgHistoriesPromise = shouldUseMockData
        ? fetchMockDataPromiseWithDelay(orgHistories, 700)
        : HistoryService.getInternalHistorySummaries(0, 10000, undefined, orgId);

      internalHistoriesPromise = shouldUseMockData
        ? fetchMockDataPromiseWithDelay(internalHistories, 6000)
        : HistoryService.getInternalHistorySummaries(0, 10000, -1);

      return defer({
        accountHistoriesPromise: accountHistoriesPromise,
        orgHistoriesPromise: orgHistoriesPromise,
        internalHistoriesPromise: internalHistoriesPromise,
      });
    } else {
      return defer({
        accountHistoriesPromise: [],
        orgHistoriesPromise: [],
        internalHistoriesPromise: [],
      });
    }
  } catch (e) {
    console.log("Error fetching dashboard data: ", e);
    return {};
  }
}
