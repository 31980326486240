import {
  formatCryptoQuantity,
  formatDollarAmountUsd,
  formatHash,
  formatWalletAddress,
  Icons,
  Link,
  Typography,
} from "@bakkt/bakkt-ui-components";
import { Alert, ClickAwayListener, IconButton, SvgIcon, Tooltip, Unstable_Grid2 as Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";

import { AssetSVGIcon } from "../../components/CustomSVG/AssetSVGIcon";
import { ReviewTransactionMinimal } from "../../Hooks/useReviewTransaction";
import { useRootContext } from "../../RootLayout";
import { AllowlistAddress, Wallet, WalletTransaction } from "../../services/openAPI/internal";
import { MIN_GAS_BALANCE } from "../../utils/constants";
import { iconsMap } from "../../utils/CryptoIconsMap";
import {
  AvailableAndPendingBalanceSummary,
  getAvailableAndPendingBalances,
  getPriceByAssetQty,
  getScanLink,
  getSVGStringForTicker,
} from "../../utils/dataUtils";

interface ReviewDepositDetailsProps {
  deposit: ReviewTransactionMinimal;
  wallet: Wallet | undefined;
  walletTransactions: WalletTransaction[];
  allowListedAddresses: AllowlistAddress[];
}

export default function ReviewDepositDetails({ deposit, wallet, walletTransactions }: ReviewDepositDetailsProps) {
  const { priceFeed, assets, networks } = useRootContext();

  const [openAddress, setOpenAddress] = useState(false);
  const [openHash, setOpenHash] = useState(false);

  const balances = getWalletBalances();

  function getWalletBalances(): AvailableAndPendingBalanceSummary {
    return getAvailableAndPendingBalances(wallet, priceFeed);
  }

  const theme = useTheme();
  const copyIconStyle = { width: 14, height: 14, ml: 1 };

  const scanLink = getScanLink(deposit?.assetTicker || "", deposit.toWalletAddress || "", assets, networks);

  const copyAddress = (address: string) => {
    setOpenAddress(true);
    navigator.clipboard.writeText(address);
  };

  const copyHash = (hash: string) => {
    setOpenHash(true);
    navigator.clipboard.writeText(hash);
  };

  const isTradingDeposit = !deposit.toWalletAddress?.startsWith("bkkt");

  const oldAssetBalance = deposit?.quantity ? balances.availableBalanceCrypto - Number(deposit?.quantity) : 0;

  return (
    <Grid container xs={12} justifyContent={"space-between"}>
      {deposit?.isAssetToken && deposit?.gasBalance && deposit?.gasBalance < MIN_GAS_BALANCE && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          <Typography variant={"subtitle2"}>
            This ERC-20 asset’s fee wallet has insufficient funds of {deposit.gasBalance} ETH.
            <Typography variant={"subtitle2"}>
              ERC-20 assets require a minimum balance of {MIN_GAS_BALANCE} ETH in {deposit.feeWallet?.name} wallet for
              GAS fees.
            </Typography>
            <Typography variant={"subtitle2"}>
              *Please fund this fee wallet {deposit.feeWallet?.address} with at least {MIN_GAS_BALANCE} ETH in order to
              proceed
            </Typography>
          </Typography>
        </Alert>
      )}

      <Grid sx={{ pb: 4 }}>
        <Typography>TRANSACTION HASH</Typography>
      </Grid>
      <Grid direction="row" sx={{ textAlign: "right", alignContent: "center" }}>
        {formatHash(deposit.transactionId as string)}
        <ClickAwayListener onClickAway={() => setOpenHash(false)}>
          <Tooltip
            open={openHash}
            onClose={() => setOpenHash(false)}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement="top"
            title="Hash Copied"
          >
            <IconButton
              sx={{ m: 0, p: 0 }}
              onClick={() => copyHash(deposit.transactionId as string)}
              disableRipple
              disableFocusRipple
            >
              <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={copyIconStyle} />
            </IconButton>
          </Tooltip>
        </ClickAwayListener>
      </Grid>

      {/* Source */}
      <Grid xs={12}>
        <Typography sx={{ fontWeight: 600 }}>Source</Typography>
        <Grid>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">ADDRESS</Typography>
            </Grid>
            <Grid direction="row" sx={{ textAlign: "right" }}>
              <Typography variant="subtitle1">
                {formatWalletAddress((wallet?.address || "") as string)}
                <ClickAwayListener onClickAway={() => setOpenAddress(false)}>
                  <Tooltip
                    open={openAddress}
                    onClose={() => setOpenAddress(false)}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement="top"
                    title="Address Copied"
                  >
                    <IconButton
                      sx={{ m: 0, p: 0 }}
                      onClick={() => copyAddress(wallet?.address as string)}
                      disableRipple
                      disableFocusRipple
                    >
                      <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={copyIconStyle} />
                    </IconButton>
                  </Tooltip>
                </ClickAwayListener>
              </Typography>
              <Link
                href={`https://kyt.chainalysis.com/transfers/?hash=${deposit.transactionId}`}
                target="_blank"
                rel="noopener"
              >
                View in Chainalysis
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Destination */}
      <Grid xs={12}>
        <Typography sx={{ fontWeight: 600 }}>Destination</Typography>
        <Grid>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">CLIENT</Typography>
            </Grid>
            <Grid direction="row" sx={{ textAlign: "right" }}>
              <Typography variant="subtitle1">{deposit.accountName}</Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">ORGANIZATION</Typography>
            </Grid>
            <Grid direction="row" sx={{ textAlign: "right" }}>
              <Typography variant="subtitle1">{deposit.organizationName}</Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">WALLET</Typography>
            </Grid>
            <Grid direction="row" sx={{ textAlign: "right" }}>
              <SvgIcon
                component={() =>
                  AssetSVGIcon({
                    svgString: getSVGStringForTicker(assets, deposit.assetTicker as string),
                    title: deposit.assetTicker as string,
                    sx: { width: 16, height: 16, mr: 1, mb: -0.3 },
                  })
                }
                inheritViewBox
              />
              {wallet?.name} ({wallet?.temperature})
              <Typography variant="subtitle1">
                {formatWalletAddress(deposit.toWalletAddress || "")}
                {isTradingDeposit && (
                  <IconButton sx={{ m: 0, p: 0 }} href={scanLink} target="_blank" rel="noopener" disableRipple={true}>
                    <SvgIcon component={Icons.GoToIcon} inheritViewBox sx={{ width: 12, ml: 1 }} />
                  </IconButton>
                )}
              </Typography>
              <Typography variant="subtitle1">ID: {wallet?.walletId}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Amounts */}
      <Grid xs={12} sx={{ pb: 3 }}>
        <Typography sx={{ fontWeight: 600 }}>Amounts</Typography>
        <Grid sx={{ borderBottom: 1, borderColor: theme.palette.divider, pb: 1 }}>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">BALANCE BEFORE DEPOSIT</Typography>
            </Grid>
            <Grid direction={"row"} sx={{ textAlign: "right" }}>
              <Typography variant="subtitle1">
                {formatCryptoQuantity(oldAssetBalance).toLocaleString()} {(wallet && wallet.assetTicker) || ""}
              </Typography>
              <Typography variant="subtitle1">
                {formatDollarAmountUsd(
                  getPriceByAssetQty(deposit.assetTicker as string, Number(oldAssetBalance), priceFeed) || 0
                )}{" "}
                USD
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">DEPOSIT AMOUNT</Typography>
            </Grid>
            <Grid direction="row" sx={{ textAlign: "right" }}>
              <Typography variant="subtitle1">
                {formatCryptoQuantity(Number(deposit.quantity)).toLocaleString()} {(wallet && wallet.assetTicker) || ""}
              </Typography>
              <Typography variant="subtitle1">
                {formatDollarAmountUsd(
                  getPriceByAssetQty(deposit.assetTicker as string, Number(deposit.quantity), priceFeed)
                ) || 0}{" "}
                USD
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">TOTAL BALANCE</Typography>
            </Grid>
            <Grid direction={"row"} sx={{ textAlign: "right" }}>
              <Typography variant="subtitle1">
                {formatCryptoQuantity(balances.availableBalanceCrypto || 0).toLocaleString()}{" "}
                {(wallet && wallet.assetTicker) || ""}
              </Typography>
              <Typography variant="subtitle1">
                {formatDollarAmountUsd(
                  getPriceByAssetQty(
                    deposit.assetTicker as string,
                    Number(balances.availableBalanceCrypto),
                    priceFeed
                  ) || 0
                )}{" "}
                USD
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
