import { AxiosError } from "axios";

import { ActionResponse } from "./customTypes";

function isAxiosError(error: AxiosError | any): error is AxiosError {
  const castedAxiosError = error as AxiosError;
  // AxiosError name field is always hardcoded to "AxiosError", so we can use this to check type
  return castedAxiosError?.name !== undefined && castedAxiosError?.name === "AxiosError";
}

function getErrorMessage(error: AxiosError | any): string {
  if (isAxiosError(error)) {
    return error.message;
  } else {
    return "An unknown error occurred.";
  }
}

export function formatActionSuccessResponse(successResponseObject: any): ActionResponse {
  return {
    success: true,
    message: successResponseObject?.message || "",
    data: successResponseObject?.data || "",
  };
}

export function formatActionErrorResponse(errorResponseObject?: AxiosError | any): ActionResponse {
  return { success: false, message: getErrorMessage(errorResponseObject) };
}
