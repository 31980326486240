import { Alert, Paper, Typography } from "@bakkt/bakkt-ui-components";
import { Container, Unstable_Grid2 as Grid } from "@mui/material";
import React from "react";
import { Await, defer, Outlet, useLoaderData } from "react-router-dom";

import LoadingIndicator from "../../components/loading/LoadingIndicator";
import { accounts, fetchMockDataPromiseWithDelay } from "../../services/mockData";
import { AccountService } from "../../services/serviceLoader";
import { shouldUseMockData } from "../../utils/dataUtils.ts";
import { ClientList } from "./ClientList";
import { innerGridContainer, subText, wrapperContainer } from "./styles";

export const Clients = () => {
  const { accounts }: any = useLoaderData();
  return (
    <>
      <Outlet />
      <Container maxWidth="xl" disableGutters sx={wrapperContainer}>
        <Typography variant="h3" data-testid="clientsHeader">
          Clients
        </Typography>
        <Typography variant="body1" sx={subText}></Typography>
        <Paper>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Container sx={innerGridContainer} data-testid="clientsListTable">
                <React.Suspense fallback={<LoadingIndicator />}>
                  <Await
                    resolve={accounts}
                    errorElement={<Alert severity="error">Error loading clients list data!</Alert>}
                  >
                    <ClientList />
                  </Await>
                </React.Suspense>
              </Container>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default Clients;

export async function loader() {
  try {
    const accountsPromise = shouldUseMockData
      ? fetchMockDataPromiseWithDelay(accounts, 200)
      : AccountService.getAccounts();

    return defer({
      accounts: await accountsPromise,
    });
  } catch (e) {
    console.log("Error fetching Clients data: ", e);
    return {};
  }
}
