import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { fetchMockDataPromiseWithDelay, wallets, walletTransactions } from "../services/mockData";
import { PolicyActionDetails, Wallet, WalletTransaction } from "../services/openAPI/internal";
import { WalletService, WalletTransactionService } from "../services/serviceLoader";
import { shouldUseMockData } from "../utils/dataUtils";

export interface CollateralMinimal {
  assetTicker: string;
  fromWalletId: number | null;
  fromWalletAddress: string | null;
  offExchangeWalletId: number | null;
  exchange: string;
  action: string;
  quantity: string | number | null;
  originatorId: number;
  createdOn: number;
  accountId: number;
  organizationId: number;
  clientName: string;
  orgName: string;
  requesterName: string;
}

const useGetCollateralRequest = (policyItem: PolicyActionDetails) => {
  const routeParams = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [collateralTransaction, setCollateralTransaction] = useState<Partial<CollateralMinimal>>(
    {} as CollateralMinimal
  );

  const [orgWallets, setOrgWallets] = useState<Wallet[]>([]);

  const orgId = Number(JSON.parse(String(policyItem.policyInstancePayload)).organizationId);

  const getCollateralTransactionDetails = async () => {
    //get all wallets by orgId
    const allOrgWallets: Wallet[] = shouldUseMockData
      ? ((await fetchMockDataPromiseWithDelay(wallets, 300)) as any)
      : ((await WalletService.getWallets(undefined, orgId)) as any);
    setOrgWallets(allOrgWallets);

    // get all transactions by orgId
    const orgTransactions: WalletTransaction[] = shouldUseMockData
      ? ((await fetchMockDataPromiseWithDelay(walletTransactions, 300)) as any)
      : ((await WalletTransactionService.getWalletTransactions(undefined, orgId)) as any);

    // find transaction item
    const walletTransactionItem = orgTransactions.find(
      (w) => w.walletTransactionId == routeParams.walletTransactionId
    ) as WalletTransaction;

    // set CollateralTransactionsDetails
    const collateralFormData: Partial<CollateralMinimal> = {
      assetTicker: walletTransactionItem.assetTicker,
      fromWalletId: walletTransactionItem.sourceId,
      fromWalletAddress: walletTransactionItem.sourceName,
      offExchangeWalletId: walletTransactionItem.destinationId,
      exchange: "DERIBIT",
      action: walletTransactionItem.destinationType,
      quantity: walletTransactionItem.quantity,
      createdOn: policyItem.timestamp,
      clientName: policyItem.client,
      orgName: policyItem.organization,
      requesterName: policyItem.requester,
    };
    setCollateralTransaction(collateralFormData);

    setIsLoading(false);
  };

  useEffect(() => {
    getCollateralTransactionDetails();
  }, []);

  return { isLoading, collateralTransaction, orgWallets };
};

export default useGetCollateralRequest;
