import { Button, DataGridPremium, formatUnixTime, Icons, UserInfo } from "@bakkt/bakkt-ui-components";
import { SvgIcon, Unstable_Grid2 as Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  GridColDef,
  GridPagination,
  GridRenderCellParams,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid-premium";
import { Link as RouterLink, useLoaderData, useRouteLoaderData } from "react-router-dom";

import { Status } from "../../services/openAPI/internal";
import { isDa } from "../../utils/permissionsUtil";

export const ClientList = () => {
  const theme = useTheme();
  const { userInfo } = useRouteLoaderData("root") as {
    userInfo: UserInfo;
  };

  const NavLinkStyle = {
    color: theme.palette.primary.main,
    textDecoration: "none",
  };
  const changeTextDecorationUnderline = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.currentTarget.style.textDecoration = "underline";
  };

  const changeTextDecorationNone = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.currentTarget.style.textDecoration = "none";
  };

  function getColorClass(value: string) {
    if (value.toUpperCase() === Status.PendingChanges || value.toUpperCase() === Status.PendingActivation) {
      return "client-status-orange-cell";
    } else {
      return "";
    }
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Grid container xs={12} justifyContent="space-between">
          <Grid>
            <GridToolbarQuickFilter />
            <GridToolbar />
          </Grid>

          {isDa(userInfo) && (
            <RouterLink to={"/clients/new"}>
              <Button variant={"contained"}>
                <SvgIcon
                  component={Icons.PlusIcon}
                  inheritViewBox
                  sx={{ width: 20, height: 20, paddingRight: "7px" }}
                />
                New Client
              </Button>
            </RouterLink>
          )}
        </Grid>
      </GridToolbarContainer>
    );
  };

  function getLinkText(params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) {
    const { status } = params.row;
    if (status.toUpperCase() === Status.Active) {
      return "View";
    } else if (status.toUpperCase() === Status.PendingChanges || status.toUpperCase() === Status.PendingActivation) {
      return "Resume";
    } else {
      return "";
    }
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 40,
      flex: 1,
    },
    {
      field: "name",
      headerName: "NAME",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <RouterLink
          to={`/clients/${params.id}/`}
          style={NavLinkStyle}
          onMouseOver={changeTextDecorationUnderline}
          onMouseLeave={changeTextDecorationNone}
        >
          {params.value}
        </RouterLink>
      ),
    },

    {
      field: "activatedOn",
      headerName: "ACTIVATION DATE",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        if (params.row.status === Status.PendingActivation) {
          return Status.PendingActivation;
        } else {
          return formatUnixTime(params.value);
        }
      },
    },
    {
      field: "location",
      headerName: "LOCATION",
      minWidth: 150,
      flex: 1,
      valueGetter: (params: any) => {
        return params.row.address.state + " , " + params.row.address.city + " , " + params.row.address.country;
      },
    },
    {
      field: "status",
      headerName: "STATUS",
      minWidth: 200,
      flex: 1,
      cellClassName: (params) => getColorClass(params.value),
    },
    {
      field: "totalAmount",
      headerName: "TOTAL AMOUNT",
      minWidth: 200,
      flex: 1,
      valueGetter: () => "N/A",
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      minWidth: 100,
      /*renderCell: (params) => <RouterLink to={`/clients/${params.id}`}>{getLinkText(params)}</RouterLink>,*/
      renderCell: (params) => (
        <RouterLink
          to={`/clients/${params.id}`}
          style={NavLinkStyle}
          onMouseOver={changeTextDecorationUnderline}
          onMouseLeave={changeTextDecorationNone}
        >
          {getLinkText(params)}
        </RouterLink>
      ),
    },
  ];
  const initialState = {
    columns: {
      columnVisibilityModel: {
        id: false,
      },
    },
  };

  const { accounts }: any = useLoaderData();
  const data = { columns, initialState, rows: accounts };

  return (
    <>
      <DataGridPremium
        slots={{
          toolbar: CustomToolbar,
          pagination: GridPagination,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        {...data}
        initialState={{
          ...data.initialState,
          pagination: { paginationModel: { pageSize: 10 } },
          sorting: { ...data.initialState?.columns, sortModel: [{ field: "name", sort: "asc" }] },
        }}
        pageSizeOptions={[10, 25, 50]}
        pagination
      />
    </>
  );
};
