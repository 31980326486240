import { Alert, Button, Dialog, DialogTitle, formatCurrency, Typography } from "@bakkt/bakkt-ui-components";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import {
  AlertTitle,
  Box,
  Collapse,
  DialogActions,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { AxiosResponse } from "axios";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  defer,
  Link as RouterLink,
  LoaderFunctionArgs,
  redirect,
  useFetcher,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import LoadingIndicator from "../../components/loading/LoadingIndicator.tsx";
import { useRootContext } from "../../RootLayout";
import { accounts, fetchMockDataPromiseWithDelay, organizations, users } from "../../services/mockData.ts";
import {
  Account,
  AccountRequest,
  Organization,
  OrganizationLevelPermission,
  OrganizationPermission,
  Status,
  User,
  UserRequest,
} from "../../services/openAPI/internal";
import { AccountService, OrganizationService, UserService } from "../../services/serviceLoader.ts";
import {
  areOrganizationsEqual,
  areUsersEqual,
  getAccount,
  getOrgs,
  getUsersByAccountId,
  mapClientFormDataToAccountRequest,
  mapOrgFormDataToOrganization,
  mapOrgFormDataToOrganizationRequest,
  mapUserFormDataToUser,
  mapUserFormDataToUserRequest,
  shouldUseMockData,
} from "../../utils/dataUtils.ts";
import { isDa } from "../../utils/permissionsUtil.ts";
import { OrganizationFormData } from "../organizations/AddEditOrganization";
import { UserFormData } from "../users/AddEditUsers";
import { ClientFormData } from "./AddEditClient";
import styles from "./Clients.module.css";

export interface AccountMultiPersist {
  accountToUpdate: ClientFormData | null;
  orgsToInsert: OrganizationFormData[];
  orgsToUpdate: OrganizationFormData[];
  usersToInsert: UserFormData[];
  usersToUpdate: UserFormData[];
  usersIdsToDelete: number[];
  isUpdateMode: boolean;
}

interface ActionResponse {
  success: boolean;
  title: string;
  message: string;
}

export default function ConfirmAccountDialog() {
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const { addAlert, userInfo } = useRootContext();
  const fetcherData = fetcher.data;
  const isLoading = fetcher.state === "submitting";
  const { account }: any = useLoaderData() as {
    account: Account;
  };
  let { organizations, users }: any = useLoaderData() as {
    organizations: Organization[];
    users: User[];
  };
  users = users?.filter((user: User) => user.status !== Status.Deleted);
  organizations = organizations?.filter((org: Organization) => org.status !== Status.Deleted);
  const buttonThreeSx = {
    selfAlign: "start",
    marginRight: "auto",
  };
  const [isConfirmAccountDialogOpen, setIsConfirmAccountDialogOpen] = useState(true);

  //For update client/account flow
  const { accountChanges, setAccountChanges } = useRootContext();
  const pathNames = useLocation()
    .pathname.split("/")
    .filter((path) => path !== "");
  const isUpdateMode = pathNames[pathNames.length - 1] === "edit" ? true : false;
  const { accountId } = useParams();
  const updatedAccount = accountChanges.account;
  const updatedOrgs = accountChanges.organizations;
  const updatedUsers = accountChanges.users;

  const accountMultiPersist: AccountMultiPersist = {
    accountToUpdate: null,
    orgsToInsert: [],
    orgsToUpdate: [],
    usersToInsert: [],
    usersToUpdate: [],
    usersIdsToDelete: [],
    isUpdateMode: false,
  };

  useEffect(() => {
    const response = isUpdateMode ? fetcher.data : (fetcher.data as AxiosResponse);
    if (isUpdateMode && response) {
      if (response?.success) {
        addAlert({
          severity: "success",
          messageHeader: response.title,
          message: response.message,
        });
      } else {
        addAlert({
          severity: "error",
          messageHeader: response.title,
          message: response.message,
        });
      }
      navigate(`/clients/${accountId}`);
    } else if (!isUpdateMode && response) {
      if (response?.status === 200) {
        addAlert({
          severity: "success",
          messageHeader: isUpdateMode ? "Successfully updated client." : "Successfully created client.",
        });
        navigate("/");
      }
    }
  }, [fetcher.data]);

  useEffect(() => {
    handleChangedValues();
  }, []);

  const handleCancel = () => {
    setIsConfirmAccountDialogOpen(false);
    if (isUpdateMode) {
      //clear RootContext
      setAccountChanges((prevState) => ({
        ...prevState,
        account: null,
        organizations: [],
        users: [],
        isOrgEdited: false,
      }));
      //to parent route
      navigate("..");
    } else {
      navigate("/");
    }
  };

  const handleGoBack = () => {
    if (isUpdateMode) {
      navigate(`/clients/${accountId}/users/edit`);
    } else {
      setIsConfirmAccountDialogOpen(false);
      navigate(-1);
    }
  };

  function handleChangedValues() {
    if (isUpdateMode) {
      accountMultiPersist.isUpdateMode = true;
      if (accountChanges.account) {
        accountMultiPersist.accountToUpdate = accountChanges.account;
      }
      if (accountChanges.organizations && accountChanges.isOrgEdited) {
        accountMultiPersist.orgsToInsert = accountChanges.organizations.filter((org) => org.id < 0);
        accountMultiPersist.orgsToUpdate = accountChanges.organizations.filter(
          (org) =>
            organizations.find((o: Organization) => o.id === org.id) &&
            !areOrganizationsEqual(
              org,
              organizations.find((o: Organization) => o.id === org.id)
            )
        );
      }
      if (accountChanges.users) {
        accountMultiPersist.usersToInsert = accountChanges.users.filter((user) => user.id === -1);
        accountMultiPersist.usersIdsToDelete = getUserIdsNotInUpdatedUsers(accountChanges.users, users);
        accountMultiPersist.usersToUpdate = accountChanges.users.filter(
          (user) =>
            users.find((u: User) => u.id === user.id) &&
            !areUsersEqual(
              user,
              users.find((u: User) => u.id === user.id)
            )
        );
      }
    }
  }

  const handleSubmit = () => {
    if (isUpdateMode) {
      fetcher.submit(JSON.stringify(accountMultiPersist), {
        method: "post",
        encType: "application/json",
      });
      //clear RootContext
      setAccountChanges((prevState) => ({
        ...prevState,
        account: null,
        organizations: [],
        users: [],
        isOrgEdited: false,
      }));
    } else {
      fetcher.submit(
        {},
        {
          method: "post",
          encType: "application/json",
        }
      );
    }
  };

  function getUserIdsNotInUpdatedUsers(updatedUsers: UserFormData[], existingUsers: User[]) {
    const userIdsInUpdatedUsers = updatedUsers.map((user) => user.id);
    const userIdsNotInUpdatedUsers = existingUsers
      .filter((user) => !userIdsInUpdatedUsers.includes(user.id))
      .map((user) => user.id);
    return userIdsNotInUpdatedUsers;
  }

  function isNameChanged() {
    if (updatedAccount !== null && updatedAccount.name !== account.name) {
      return true;
    }
    return false;
  }

  function isStreet1Changed() {
    if (updatedAccount !== null && updatedAccount.street1 !== account.address.street1) {
      return true;
    }
    return false;
  }

  function isPhoneChanged() {
    if (
      updatedAccount !== null &&
      updatedAccount.phone?.countryCode + "" + updatedAccount.phone?.number !==
        account.phone?.countryCode + "" + account.phone?.number
    ) {
      return true;
    }
    return false;
  }

  function isStreet2Changed() {
    if (updatedAccount !== null && updatedAccount.street2 !== account.address.street2) {
      return true;
    }
    return false;
  }

  function isCountryChanged() {
    if (updatedAccount !== null && updatedAccount.country !== account.address.country) {
      return true;
    }
    return false;
  }

  function isCityChanged() {
    if (updatedAccount !== null && updatedAccount.city !== account.address.city) {
      return true;
    }
    return false;
  }

  function isZipCodeChanged() {
    if (updatedAccount !== null && updatedAccount.zipCode !== account.address.zipCode) {
      return true;
    }
    return false;
  }

  function isStateChanged() {
    if (updatedAccount !== null && updatedAccount.state !== account.address.state) {
      return true;
    }
    return false;
  }

  function isOrgNameChanged(row: OrganizationFormData, organizations: Organization[]) {
    const existingOrg = organizations.find((org) => org.id === row.id);
    if (!existingOrg || row.name !== existingOrg.name) {
      return true;
    }
    return false;
  }

  function isOrgNumOfApproversChanged(row: OrganizationFormData, organizations: Organization[]) {
    const existingOrg = organizations.find((org) => org.id === row.id);
    if (!existingOrg || row.numberOfConsensus !== existingOrg.numberOfConsensus) {
      return true;
    }
    return false;
  }

  function isOrgVideoAuthChanged(row: OrganizationFormData, organizations: Organization[]) {
    const existingOrg = organizations.find((org) => org.id === row.id);
    if (!existingOrg || row.withdrawalAuthorization.amountLimit !== existingOrg.withdrawalAuthorization.amountLimit) {
      return true;
    }
    return false;
  }

  return (
    <Box>
      <Dialog open={isConfirmAccountDialogOpen} onClose={handleGoBack} maxWidth={"xl"}>
        <DialogTitle title="Review and Confirm Details">
          Please review client, organization, and user details to create client.
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Grid sx={{ minHeight: 250 }}>
              <LoadingIndicator
                description={
                  isUpdateMode
                    ? "Client update is being submitted. Please wait..."
                    : "Client is being created. Please wait..."
                }
              />
            </Grid>
          ) : (
            <>
              {fetcherData && !isUpdateMode && (
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {fetcherData.message || "Failed to create client. Please try again later or contact support."}
                </Alert>
              )}
              <Grid container spacing={2} sx={{ width: "1024px", mb: "40px" }}>
                <Grid xs={6}>
                  <Typography variant="h4" sx={{ fontWeight: 400 }}>
                    Client Details
                  </Typography>
                </Grid>
                <Grid xs={6} sx={{ textAlign: "right" }}>
                  <RouterLink to={`/clients/${accountId}/edit`}>
                    <Button autoFocus variant="outlined">
                      Edit
                    </Button>
                  </RouterLink>
                </Grid>
                <Grid xs={6} sx={{ mt: "20px" }}>
                  <Typography variant="h5">Client Name</Typography>
                  <span className={isNameChanged() ? styles["Updated-Field-Style"] : ""}>
                    {updatedAccount ? updatedAccount.name : account.name}
                  </span>
                </Grid>
                <Grid xs={6} sx={{ mt: "20px" }}>
                  <Typography variant="h5">Address Line 1</Typography>
                  <span className={isStreet1Changed() ? styles["Updated-Field-Style"] : ""}>
                    {updatedAccount ? updatedAccount.street1 : account.address?.street1}
                  </span>
                </Grid>
                <Grid xs={6} sx={{ mt: "20px" }}>
                  <Typography variant="h5">Phone</Typography>{" "}
                  <span className={isPhoneChanged() ? styles["Updated-Field-Style"] : ""}>
                    {updatedAccount
                      ? updatedAccount.phone?.countryCode + "" + updatedAccount.phone?.number
                      : account.phone?.countryCode + "" + account.phone?.number}
                  </span>
                </Grid>
                <Grid xs={6} sx={{ mt: "20px" }}>
                  <Typography variant="h5">Address Line 2</Typography>
                  <span className={isStreet2Changed() ? styles["Updated-Field-Style"] : ""}>
                    {updatedAccount ? updatedAccount.street2 : account.address?.street2}
                  </span>
                </Grid>
                <Grid xs={6} sx={{ mt: "20px" }}>
                  <Typography variant="h5">Country</Typography>
                  <span className={isCountryChanged() ? styles["Updated-Field-Style"] : ""}>
                    {updatedAccount ? updatedAccount.country : account.address?.country}
                  </span>
                </Grid>
                <Grid xs={6} sx={{ mt: "20px" }}>
                  <Typography variant="h5">City</Typography>
                  <span className={isCityChanged() ? styles["Updated-Field-Style"] : ""}>
                    {updatedAccount ? updatedAccount.city : account.address?.city}
                  </span>
                </Grid>
                <Grid xs={6} sx={{ mt: "20px" }}>
                  <Typography variant="h5">Zip/Postal Code</Typography>
                  <span className={isZipCodeChanged() ? styles["Updated-Field-Style"] : ""}>
                    {updatedAccount ? updatedAccount.zipCode : account.address?.zipCode}
                  </span>
                </Grid>
                <Grid xs={6} sx={{ mt: "20px" }}>
                  <Typography variant="h5">State/Provence</Typography>
                  <span className={isStateChanged() ? styles["Updated-Field-Style"] : ""}>
                    {updatedAccount ? updatedAccount.state : account.address?.state}
                  </span>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ width: "1024px", mb: "40px" }}>
                <Grid xs={6}>
                  <Typography variant="h4" sx={{ fontWeight: 400 }}>
                    Organizations
                  </Typography>
                </Grid>
                <Grid xs={6} sx={{ textAlign: "right" }}>
                  <RouterLink to={`/clients/${accountId}/organizations/edit`}>
                    <Button autoFocus variant="outlined">
                      Edit
                    </Button>
                  </RouterLink>
                </Grid>

                <Grid xs={12}>
                  <TableContainer>
                    <Table sx={{ width: "100%" }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>NAME</TableCell>
                          <TableCell>NUMBER OF APPROVERS</TableCell>
                          <TableCell>WITHDRAW VIDEO AUTH</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {updatedOrgs && updatedOrgs.length > 0
                          ? updatedOrgs.map((row: OrganizationFormData, index: number) => (
                              <TableRow
                                key={row.name + "-" + index}
                                sx={{
                                  "&:last-child td, &:last-child th": { border: 0 },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  <span
                                    className={
                                      isOrgNameChanged(row, organizations) ? styles["Updated-Field-Style"] : ""
                                    }
                                  >
                                    {row.name}
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <span
                                    className={
                                      isOrgNumOfApproversChanged(row, organizations)
                                        ? styles["Updated-Field-Style"]
                                        : ""
                                    }
                                  >
                                    {row.numberOfConsensus}
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <span
                                    className={
                                      isOrgVideoAuthChanged(row, organizations) ? styles["Updated-Field-Style"] : ""
                                    }
                                  >
                                    {formatCurrency(Number(row.withdrawalAuthorization?.amountLimit))}
                                  </span>
                                </TableCell>
                              </TableRow>
                            ))
                          : organizations.map((row: Organization, index: number) => (
                              <TableRow
                                key={row.name + "-" + index}
                                sx={{
                                  "&:last-child td, &:last-child th": { border: 0 },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.name}
                                </TableCell>
                                <TableCell>{row.numberOfConsensus}</TableCell>
                                <TableCell>
                                  {formatCurrency(Number(row.withdrawalAuthorization?.amountLimit))}
                                </TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ width: "1024px", mb: "40px" }}>
                <Grid xs={6}>
                  <Typography variant="h4" sx={{ fontWeight: 400 }}>
                    Users
                  </Typography>
                </Grid>
                <Grid xs={6} sx={{ textAlign: "right" }}>
                  <RouterLink to={`/clients/${accountId}/users/edit`}>
                    <Button autoFocus variant="outlined">
                      Edit
                    </Button>
                  </RouterLink>
                </Grid>

                <Grid xs={12}>
                  <TableContainer>
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell>LAST NAME</TableCell>
                          <TableCell>FIRST NAME</TableCell>
                          <TableCell>EMAIL</TableCell>
                          <TableCell>PHONE</TableCell>
                          <TableCell>MANAGER</TableCell>
                          <TableCell>POSITION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {updatedUsers && updatedUsers.length > 0
                          ? updatedUsers.map((row: UserFormData, index: number) => (
                              <Row
                                key={row.id + "-" + index}
                                row={mapUserFormDataToUser(row, Number(accountId))}
                                organizations={
                                  updatedOrgs.length > 0 ? mapOrgFormDataToOrganization(updatedOrgs) : organizations
                                }
                                users={users}
                              />
                            ))
                          : users.map((row: User, index: number) => (
                              <Row
                                key={row.id + "-" + index}
                                row={row}
                                organizations={
                                  updatedOrgs.length > 0 ? mapOrgFormDataToOrganization(updatedOrgs) : organizations
                                }
                              />
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGoBack} sx={buttonThreeSx} variant="outlined">
            Go Back
          </Button>
          <Button onClick={handleCancel} autoFocus variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmit} autoFocus variant="contained" disabled={!isDa(userInfo)}>
            Save & continue
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

function isUserFirstNameChanged(row: User, users: User[] | undefined) {
  if (!users) {
    return false;
  }
  const existingUser = users.find((user) => user.id === row.id);
  if (!existingUser || row.name.firstName !== existingUser.name.firstName) {
    return true;
  }
  return false;
}

function isUserLastNameChanged(row: User, users: User[] | undefined) {
  if (!users) {
    return false;
  }
  const existingUser = users.find((user) => user.id === row.id);
  if (!existingUser || row.name.lastName !== existingUser.name.lastName) {
    return true;
  }
  return false;
}

function isUserEmailChanged(row: User, users: User[] | undefined) {
  if (!users) {
    return false;
  }
  const existingUser = users.find((user) => user.id === row.id);
  if (!existingUser || row.email !== existingUser.email) {
    return true;
  }
  return false;
}

function isUserPhoneNumberChanged(row: User, users: User[] | undefined) {
  if (!users) {
    return false;
  }
  const existingUser = users.find((user) => user.id === row.id);
  if (
    !existingUser ||
    row.phone?.countryCode + "" + row.phone?.number !==
      existingUser.phone?.countryCode + "" + existingUser.phone?.number
  ) {
    return true;
  }
  return false;
}

function isUserPositionChanged(row: User, users: User[] | undefined) {
  if (!users) {
    return false;
  }
  const existingUser = users.find((user) => user.id === row.id);
  if (!existingUser || row.position !== existingUser.position) {
    return true;
  }
  return false;
}

function isUserAccountPermissionChanged(row: User, users: User[] | undefined) {
  if (!users) {
    return false;
  }
  const existingUser = users.find((user) => user.id === row.id);
  const existingPermission =
    existingUser && "accountPermissions" in existingUser
      ? existingUser.accountPermissions?.activePermissions?.length
      : 0;
  const newPermission = row && "accountPermissions" in row ? row.accountPermissions?.activePermissions?.length : 0;
  if (row.id === -1 || existingPermission !== newPermission) {
    return true;
  }
  return false;
}

function isOrgPermissionsChanged(row: User, orgPermissions: OrganizationLevelPermission, users: User[] | undefined) {
  //We pass users array only when users are updated, if users were not passed to Row then no update was done to users.
  if (!users) {
    return false;
  }
  const existingPermissions =
    users
      .find((usr) => usr.id === row.id)
      ?.organizations?.find((orgLevel) => orgLevel.organizationId === orgPermissions.organizationId)
      ?.activePermissions || [];
  const newPermissions =
    row.organizations?.find((org: OrganizationLevelPermission) => org.organizationId === orgPermissions.organizationId)
      ?.activePermissions || [];

  if (existingPermissions.length !== newPermissions.length) {
    return true;
  }
  const unmatchedPermissions = existingPermissions.filter((perm) => !newPermissions.includes(perm));

  if (unmatchedPermissions.length > 0) {
    return true;
  }
  return false;
}

function Row(props: { row: User; organizations: Organization[]; users?: User[] | undefined }) {
  const { row, organizations, users: existingUsers } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <span className={isUserFirstNameChanged(row, existingUsers) ? styles["Updated-Field-Style"] : ""}>
            {row.name.firstName}
          </span>
        </TableCell>
        <TableCell>
          <span className={isUserLastNameChanged(row, existingUsers) ? styles["Updated-Field-Style"] : ""}>
            {row.name.lastName}
          </span>
        </TableCell>
        <TableCell>
          <span className={isUserEmailChanged(row, existingUsers) ? styles["Updated-Field-Style"] : ""}>
            {row.email}
          </span>
        </TableCell>
        <TableCell>
          <span className={isUserPhoneNumberChanged(row, existingUsers) ? styles["Updated-Field-Style"] : ""}>
            {row.phone.countryCode + "" + row.phone.number}
          </span>
        </TableCell>
        <TableCell>
          <span className={isUserAccountPermissionChanged(row, existingUsers) ? styles["Updated-Field-Style"] : ""}>
            {row.accountPermissions?.activePermissions?.length === 1 ? "Yes" : "No"}
          </span>
        </TableCell>
        <TableCell>
          <span className={isUserPositionChanged(row, existingUsers) ? styles["Updated-Field-Style"] : ""}>
            {row.position}
          </span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead></TableHead>
                <TableBody>
                  {row.organizations?.map((permissionRow: OrganizationLevelPermission, index) => (
                    <TableRow key={"permissionRow-" + index}>
                      <TableCell component="th" scope="row">
                        {permissionRow.activePermissions?.map((permission: OrganizationPermission, index) => {
                          const permissionSeparatingComma =
                            (permissionRow.activePermissions?.length || 0) - 1 === index ? "" : ", ";
                          const orgName = organizations.find((org) => org.id === permissionRow.organizationId)?.name;
                          const orgNameFormatted = "[" + orgName + "]: ";
                          const firstElementOrgName = index === 0 ? orgNameFormatted : "";
                          return (
                            <React.Fragment key={"orgLevelPermission-" + index}>
                              <span
                                className={
                                  isOrgPermissionsChanged(row, permissionRow, existingUsers)
                                    ? styles["Updated-Field-Style"]
                                    : ""
                                }
                              >
                                {firstElementOrgName + permission + permissionSeparatingComma}
                              </span>
                            </React.Fragment>
                          );
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export async function loader({ params }: LoaderFunctionArgs) {
  const accountId = Number(params.accountId);

  const accountPromise = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(getAccount(accountId, accounts) as object, 200)
    : AccountService.getAccount(accountId);

  const orgsPromise = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(getOrgs(accountId, organizations), 200)
    : OrganizationService.getOrganizations(accountId);

  const usersPromise = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(getUsersByAccountId(accountId, users), 200)
    : UserService.getUsers(accountId);

  return defer({
    account: await accountPromise,
    organizations: await orgsPromise,
    users: await usersPromise,
  });
}

function updateOrgIdsForUsers(users: UserFormData[], oldOrgId: number, newOrgId: number) {
  users.map((user) => {
    if (user.organizations.find((org) => org.organizationId === oldOrgId)) {
      const orgLevelPerm = user.organizations.find((org) => org.organizationId === oldOrgId);
      if (orgLevelPerm) {
        orgLevelPerm.organizationId = newOrgId;
      }
    }
  });
}

async function insertNewOrgsAndUpdateUsers(accountMultiPersist: AccountMultiPersist, accountId: number) {
  return accountMultiPersist.orgsToInsert.map(async (formOrgRequest) => {
    const tempOrgId = formOrgRequest.id;
    const createOrgsResponse = await OrganizationService.createOrganizations(
      mapOrgFormDataToOrganizationRequest([formOrgRequest], accountId)
    );
    //Use the orgId returned from DB to update the orgId of users that are associated with this org.

    updateOrgIdsForUsers(
      accountMultiPersist.usersToInsert,
      tempOrgId,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (createOrgsResponse[0] as unknown as Organization).id
    );

    updateOrgIdsForUsers(
      accountMultiPersist.usersToUpdate,
      tempOrgId,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (createOrgsResponse[0] as unknown as Organization).id
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return createOrgsResponse[0];
  });
}

async function updateOrganizations(orgsToUpdate: OrganizationFormData[]) {
  return orgsToUpdate.map(async (org) => {
    const formOrgRequest = mapOrgFormDataToOrganizationRequest([org])[0];
    const updateOrgResponse = await OrganizationService.updateOrganization(org.id, formOrgRequest);
    return updateOrgResponse;
  });
}

async function createUsers(usersToInsert: UserFormData[], accountId: number) {
  const formUsersRequest = mapUserFormDataToUserRequest(usersToInsert, accountId) as UserRequest[];
  const createUsersResponse = await UserService.createUsers(formUsersRequest);
  return createUsersResponse;
}

async function updateUsers(usersToUpdate: UserFormData[]) {
  return usersToUpdate.map(async (user) => {
    const formUserRequest = mapUserFormDataToUserRequest([user])[0];
    const updateUserResponse = await UserService.updateUser(user.id, formUserRequest);
    return updateUserResponse;
  });
}

async function deleteUsers(usersIdsToDelete: number[]) {
  return usersIdsToDelete.map(async (userId) => {
    const deleteUserResponse = await UserService.deleteUser(userId);
    return deleteUserResponse;
  });
}
export async function action({ request, params }: { params: LoaderFunctionArgs | any; request: Request }) {
  try {
    const accountId = Number(params.accountId);
    const accountMultiPersist = (await request.json()) as AccountMultiPersist;
    if (accountMultiPersist.isUpdateMode) {
      const errors = [];
      if (accountMultiPersist.accountToUpdate) {
        const formAccountRequest = mapClientFormDataToAccountRequest(
          accountMultiPersist.accountToUpdate
        ) as AccountRequest;
        try {
          const updateAccountResponse = await AccountService.updateAccount(accountId, formAccountRequest);
        } catch (error) {
          if (typeof error === "object" && error !== null && "message" in error) {
            errors.push("Update Account: " + error.message + "\n");
          } else {
            errors.push("Update Account: " + "Error doesn't have message property. An unknown error occurred.\n");
          }
          console.error(error);
        }
      }
      if (accountMultiPersist.orgsToInsert.length > 0) {
        const responses = await insertNewOrgsAndUpdateUsers(accountMultiPersist, accountId);
        try {
          const results = await Promise.all(responses);
          if (accountMultiPersist.usersToInsert.length > 0) {
            try {
              const createdUsersPromise = await createUsers(accountMultiPersist.usersToInsert, accountId);
              accountMultiPersist.usersToInsert = [];
            } catch (error) {
              if (typeof error === "object" && error !== null && "message" in error) {
                errors.push("Create Users: " + error.message + "\n");
              } else {
                errors.push("Create Users error: Error doesn't have message property. An unknown error occurred.\n");
              }
              console.error(error);
            }
          }
          if (accountMultiPersist.usersToUpdate.length > 0) {
            const updatedUsersPromises = await updateUsers(accountMultiPersist.usersToUpdate);
            try {
              const updatedUsersResults = await Promise.all(updatedUsersPromises);
              accountMultiPersist.usersToUpdate = [];
            } catch (error) {
              if (typeof error === "object" && error !== null && "message" in error) {
                errors.push("Update users: " + error.message + "\n");
              } else {
                errors.push("Update users error: Error doesn't have message property. An unknown error occurred.\n");
              }
              console.error(error);
            }
          }
        } catch (error) {
          if (typeof error === "object" && error !== null && "message" in error) {
            errors.push("Create Organizations error:" + error.message + "\n");
          } else {
            errors.push("Create Organizations: Error doesn't have message property. An unknown error occurred.\n");
          }
          console.error(error);
        }
      }
      if (accountMultiPersist.usersIdsToDelete.length > 0) {
        const deletedUserIdsPromises = await deleteUsers(accountMultiPersist.usersIdsToDelete);
        try {
          const deletedUserIdsResults = await Promise.all(deletedUserIdsPromises);
        } catch (error) {
          if (typeof error === "object" && error !== null && "message" in error) {
            errors.push("Delete users Error: " + error.message + "\n");
          } else {
            errors.push("Delete users: Error doesn't have message property. An unknown error occurred.\n");
          }
          console.error(error);
        }
      }

      if (accountMultiPersist.orgsToUpdate.length > 0) {
        const updatedOrgsPromises = await updateOrganizations(accountMultiPersist.orgsToUpdate);
        try {
          const updatedOrgsResults = await Promise.all(updatedOrgsPromises);
        } catch (error) {
          if (typeof error === "object" && error !== null && "message" in error) {
            errors.push("Update Organizations error:" + error.message + "\n");
          } else {
            errors.push("Error doesn't have message property. An unknown error occurred.");
          }
          console.error(error);
        }
      }
      if (accountMultiPersist.usersToInsert.length > 0) {
        try {
          const createdUsersPromise = await createUsers(accountMultiPersist.usersToInsert, accountId);
        } catch (error) {
          if (typeof error === "object" && error !== null && "message" in error) {
            errors.push("Create Users: " + error.message + "\n");
          } else {
            errors.push("Create Users error: Error doesn't have message property. An unknown error occurred.\n");
          }
          console.error(error);
        }
      }

      if (accountMultiPersist.usersToUpdate.length > 0) {
        const updatedUsersPromises = await updateUsers(accountMultiPersist.usersToUpdate);
        try {
          const updatedUsersResults = await Promise.all(updatedUsersPromises);
        } catch (error) {
          if (typeof error === "object" && error !== null && "message" in error) {
            errors.push("Update users: " + error.message + "\n");
          } else {
            errors.push("Update users error: Error doesn't have message property. An unknown error occurred.\n");
          }
          console.error(error);
        }
      }
      if (
        accountMultiPersist.accountToUpdate ||
        accountMultiPersist.orgsToInsert.length > 0 ||
        accountMultiPersist.orgsToUpdate.length > 0 ||
        accountMultiPersist.usersToInsert.length > 0 ||
        accountMultiPersist.usersIdsToDelete.length > 0 ||
        accountMultiPersist.usersToUpdate.length > 0
      ) {
        try {
          const confirmAccountPromise = await AccountService.activateAccount(accountId);
        } catch (error) {
          if (typeof error === "object" && error !== null && "message" in error) {
            errors.push("Activate account: " + error.message + "\n");
          } else {
            errors.push("Activate account error doesn't have message property. An unknown error occurred.\n");
          }
          console.error(error);
        }
        const response: ActionResponse =
          errors.length > 0
            ? {
                success: false,
                title:
                  "Some errors occurred during submission, Please try again or reach out to support for assistance.",
                message: errors.toString(),
              }
            : { success: true, title: "Success", message: "All submission successful." };
        return response;
      }
    } else {
      const confirmAccountResponse = await AccountService.activateAccount(accountId);
      return redirect(`/`);
    }
  } catch (e) {
    return e;
  }
}
