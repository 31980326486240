import { ThemeProvider, themes, Typography } from "@bakkt/bakkt-ui-components";
import { Container, Grid } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { AxiosError } from "axios";
import { useRouteError } from "react-router-dom";

import LoadingIndicator from "../../components/loading/LoadingIndicator.tsx";

export default function ErrorPage() {
  const error = useRouteError() as AxiosError | any;
  console.error(error);

  return (
    <ThemeProvider theme={themes.light}>
      <CssBaseline />
      {error?.response?.status === 401 ? (
        <Grid container xs={12} justifyContent={"center"} alignItems={"center"} sx={{ minHeight: "600px" }}>
          <LoadingIndicator />
        </Grid>
      ) : (
        <Container maxWidth="md">
          <Typography variant={"h4"}>Error</Typography>
          <Typography variant={"h6"}>
            Sorry, an unexpected error has occurred. Please contact support or try again later.
          </Typography>
        </Container>
      )}
    </ThemeProvider>
  );
}
