import {
  formatCryptoQuantity,
  formatDollarAmountUsd,
  formatWalletAddress,
  Icons,
  Typography,
} from "@bakkt/bakkt-ui-components";
import { Alert, ClickAwayListener, IconButton, SvgIcon, Tooltip, Unstable_Grid2 as Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";

import { AssetSVGIcon } from "../../components/CustomSVG/AssetSVGIcon";
import { ReviewTransactionMinimal } from "../../Hooks/useReviewTransaction";
import { useRootContext } from "../../RootLayout";
import {
  AllowlistAddress,
  AllowlistType,
  EstimateFee,
  Wallet,
  WalletTransaction,
  WalletTransactionTypeEnum,
  WalletType,
} from "../../services/openAPI/internal";
import { CryptoTickerEnum } from "../../utils/CryptoIconsMap";
import {
  AvailableAndPendingBalanceSummary,
  getAvailableAndPendingBalances,
  getPriceByAssetQty,
  getScanLink,
  getSVGStringForTicker,
  isAssetToken,
} from "../../utils/dataUtils";

interface ReviewWithdrawDetailsProps {
  withdraw: ReviewTransactionMinimal;
  wallet: Wallet | null | undefined;
  walletTransactions: WalletTransaction[];
  allowListedAddresses: AllowlistAddress[];
  feeEstimation: EstimateFee;
}

export default function ReviewWithdrawDetails({
  withdraw,
  wallet,
  walletTransactions,
  allowListedAddresses,
  feeEstimation,
}: ReviewWithdrawDetailsProps) {
  const { priceFeed, networks, assets } = useRootContext();

  const [showSelectedAddressConfirm, setShowSelectedAddressConfirm] = useState(false);
  const [showWalletAddressConfirm, setShowWalletAddressConfirm] = useState(false);
  const selectedAddress = allowListedAddresses?.find((w) => w.id === withdraw.destinationId);

  const isToken = isAssetToken(selectedAddress?.assetSymbol as string, assets);
  const theme = useTheme();

  const filteredTransactions = withdraw
    ? walletTransactions.filter((t) => t.walletTransactionId !== withdraw.walletTransactionId)
    : walletTransactions;

  const balances = getWalletBalances();

  function getWalletBalances(): AvailableAndPendingBalanceSummary {
    return getAvailableAndPendingBalances(wallet, priceFeed);
  }

  const pending = wallet?.quantity ? wallet.quantity - balances.availableBalanceCrypto : 0;
  const availableBalanceCrypto = balances.availableBalanceCrypto + Number(withdraw?.quantity);
  const updatedBalanceCrypto = availableBalanceCrypto - Number(withdraw?.quantity);

  const copySelectedAddress = (address: string) => {
    setShowSelectedAddressConfirm(true);
    navigator.clipboard.writeText(address || "");
  };

  const copyWalletAddress = (address: string) => {
    setShowWalletAddressConfirm(true);
    navigator.clipboard.writeText(address || "");
  };

  const goToIconStyle = { width: 14, height: 14 };
  const copyIconStyle = { width: 15, height: 15, ml: 1, mr: 1.2 };

  const scanSelectedAddress = getScanLink(
    selectedAddress?.assetTicker || "",
    selectedAddress?.address || "",
    assets,
    networks
  );

  const scanWalletAddress = getScanLink(wallet?.assetTicker || "", wallet?.address || "", assets, networks);
  const networkFeeTicker = isToken ? CryptoTickerEnum.ETH : (withdraw.assetTicker as string);

  return (
    <Grid container spacing={1}>
      {withdraw.isAssetToken && (
        <Alert severity="warning">
          <Typography variant={"body2"}>
            {wallet?.type === WalletType.Custody ? withdraw.organizationName : "Cold Trust Fee"}, GAS Balance:{" "}
            {withdraw.feeWallet?.availableBalance?.toFixed(8)} {CryptoTickerEnum.ETH}
          </Typography>
          <Typography variant={"body2"}>
            *Please ensure that the client has a balance of at least 0.05 ETH for GAS before processing this withdrawal.
          </Typography>
        </Alert>
      )}
      {wallet?.type === WalletType.Trading && withdraw.transactionType === WalletTransactionTypeEnum.Withdraw && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          <Typography variant={"body2"}>
            ATTENTION: This is a withdrawal transaction from the Bakkt Trust Cold aggregate wallet.
          </Typography>
        </Alert>
      )}
      <Grid xs={12}>
        <Typography sx={{ fontWeight: 600 }}>Source</Typography>
        <Grid>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">CLIENT</Typography>
            </Grid>
            <Grid>
              <Typography variant="subtitle1">{withdraw.accountName}</Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">ORGANIZATION</Typography>
            </Grid>
            <Grid>
              <Typography variant="subtitle1">{withdraw.organizationName}</Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">REQUESTED BY</Typography>
            </Grid>
            <Grid>
              <Typography variant="subtitle1">{withdraw.originatorName}</Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">WALLET</Typography>
            </Grid>
            <Grid>
              <Typography variant="subtitle1">
                <SvgIcon
                  component={() =>
                    AssetSVGIcon({
                      svgString: getSVGStringForTicker(assets, withdraw.assetTicker as string),
                      title: withdraw.assetTicker as string,
                      sx: { width: 16, height: 16, mr: 1, mb: -0.3 },
                    })
                  }
                  inheritViewBox
                />
                {wallet?.name}({wallet?.temperature})
              </Typography>
              <Grid direction="row" sx={{ textAlign: "right" }}>
                {formatWalletAddress((wallet?.address || "") as string)}
                <ClickAwayListener onClickAway={() => setShowWalletAddressConfirm(false)}>
                  <Tooltip
                    onClose={() => setShowWalletAddressConfirm(false)}
                    open={showWalletAddressConfirm}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement="top"
                    title="Address Copied"
                  >
                    <IconButton
                      sx={{ m: 0, p: 0 }}
                      onClick={() => copyWalletAddress(wallet?.address as string)}
                      disableRipple
                    >
                      <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={copyIconStyle} />
                    </IconButton>
                  </Tooltip>
                </ClickAwayListener>
                {wallet?.type === WalletType.Custody && (
                  <IconButton
                    sx={{ m: 0, p: 0 }}
                    href={scanWalletAddress}
                    target="_blank"
                    rel="noopener"
                    disableRipple={true}
                  >
                    <SvgIcon component={Icons.GoToIcon} inheritViewBox sx={goToIconStyle} />
                  </IconButton>
                )}
              </Grid>
              <Typography variant="subtitle2" sx={{ textAlign: "right" }}>
                #{wallet?.walletId}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <Typography sx={{ fontWeight: 600 }}>Destination</Typography>
        <Grid>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">
                {selectedAddress?.type === AllowlistType.Internal ||
                withdraw.transactionType === WalletTransactionTypeEnum.Transfer
                  ? "INTERNAL"
                  : "EXTERNAL ENTITY"}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="subtitle1" sx={{ textAlign: "right" }}>
                {withdraw.transactionType === WalletTransactionTypeEnum.Withdraw
                  ? selectedAddress?.name
                  : withdraw.destinationName}
              </Typography>
              <Grid direction="row" sx={{ textAlign: "right" }}>
                {formatWalletAddress(
                  withdraw.transactionType === WalletTransactionTypeEnum.Withdraw
                    ? (selectedAddress?.address as string | "")
                    : (withdraw.toWalletAddress as string | "")
                )}
                <ClickAwayListener onClickAway={() => setShowSelectedAddressConfirm(false)}>
                  <Tooltip
                    onClose={() => setShowSelectedAddressConfirm(false)}
                    open={showSelectedAddressConfirm}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement="top"
                    title="Address Copied"
                  >
                    <IconButton
                      sx={{ m: 0, p: 0 }}
                      onClick={() =>
                        copySelectedAddress(
                          withdraw.transactionType === WalletTransactionTypeEnum.Withdraw
                            ? (selectedAddress?.address as string)
                            : (withdraw.toWalletAddress as string)
                        )
                      }
                      disableRipple
                    >
                      <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={copyIconStyle} />
                    </IconButton>
                  </Tooltip>
                </ClickAwayListener>
                {withdraw.transactionType === WalletTransactionTypeEnum.Withdraw && (
                  <>
                    <IconButton
                      sx={{ m: 0, p: 0 }}
                      href={scanSelectedAddress}
                      target="_blank"
                      rel="noopener"
                      disableRipple={true}
                    >
                      <SvgIcon component={Icons.GoToIcon} inheritViewBox sx={goToIconStyle} />
                    </IconButton>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <Typography sx={{ fontWeight: 600 }}>Amounts</Typography>
        <Grid sx={{ borderBottom: 1, borderColor: theme.palette.divider, pb: 1 }}>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">AVAILABLE BALANCE</Typography>
            </Grid>
            <Grid direction={"row"} sx={{ textAlign: "right" }}>
              <Typography variant="subtitle1">
                {formatCryptoQuantity(availableBalanceCrypto || 0).toLocaleString()}{" "}
                {(wallet && wallet.assetTicker) || ""}
              </Typography>
              <Typography variant="subtitle1">
                {formatDollarAmountUsd(
                  getPriceByAssetQty(withdraw.assetTicker as string, Number(availableBalanceCrypto), priceFeed) || 0
                )}{" "}
                USD
              </Typography>
              <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                ( {pending} ) {(wallet && wallet.assetTicker) || ""} Pending
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">{withdraw.transactionType} AMOUNT</Typography>
            </Grid>
            <Grid direction="row" sx={{ textAlign: "right" }}>
              <Typography variant="subtitle1">
                {formatCryptoQuantity(Number(withdraw.quantity)).toLocaleString()}{" "}
                {(wallet && wallet.assetTicker) || ""}
              </Typography>
              <Typography variant="subtitle1">
                {formatDollarAmountUsd(
                  getPriceByAssetQty(withdraw.assetTicker as string, Number(withdraw.quantity), priceFeed) || 0
                )}{" "}
                USD
              </Typography>
            </Grid>
          </Grid>
          {withdraw.transactionType === WalletTransactionTypeEnum.Withdraw && (
            <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
              <Grid>
                <Typography variant="subtitle2">NETWORK FEE ESTIMATION</Typography>
              </Grid>
              <Grid direction="row" sx={{ textAlign: "right" }}>
                <Typography variant="subtitle1">
                  {formatCryptoQuantity(
                    Number(feeEstimation?.networkFee) || 0,
                    selectedAddress?.assetTicker || "",
                    "long"
                  )}{" "}
                  {isToken
                    ? CryptoTickerEnum.ETH
                    : ((selectedAddress?.address as string) && selectedAddress?.assetTicker) || ""}
                </Typography>
                <Typography variant="subtitle1">
                  {formatDollarAmountUsd(
                    getPriceByAssetQty(networkFeeTicker, Number(feeEstimation?.networkFee), priceFeed) || 0
                  )}{" "}
                  USD
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">UPDATED BALANCE</Typography>
            </Grid>
            <Grid direction={"row"} sx={{ textAlign: "right" }}>
              <Typography variant="subtitle1">
                {formatCryptoQuantity(updatedBalanceCrypto || 0).toLocaleString()}{" "}
                {(wallet && wallet.assetTicker) || ""}
              </Typography>
              <Typography variant="subtitle1">
                {formatDollarAmountUsd(
                  getPriceByAssetQty(withdraw.assetTicker as string, Number(updatedBalanceCrypto), priceFeed) || 0
                )}{" "}
                USD
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
