import { createSvgIcon } from "@mui/material";
import parse from "html-react-parser";

export interface SVGProps {
  svgString: string;
  title: string;
  sx: object;
}

interface SVGIconProps {
  height: string;
  fill: string;
  width: string;
  viewBox: string;
  xmlns: string;
}

export const AssetSVGIcon = ({ svgString, title, sx }: SVGProps) => {
  const svg = parse(svgString);
  const svgIconProps = getSvgProps(svg) as Partial<SVGIconProps>;
  const Icon = createSvgIcon(svg, title);

  return <Icon sx={sx} viewBox={svgIconProps.viewBox} />;
};

function getSvgProps(svg: any) {
  for (const [key, value] of Object.entries(svg)) {
    if (key === "props") {
      return value;
    }
  }
  // this is a callback viewBox can be deleted.
  return { viewBox: "0 0 120 120" };
}
