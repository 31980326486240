import { Alert, Button, DataGridPremium, Icons, Link, Switch, Tab, UserInfo } from "@bakkt/bakkt-ui-components";
import { Box, Container, FormControlLabel, SvgIcon, Tabs, Unstable_Grid2 as Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { GridColDef, GridToolbarContainer } from "@mui/x-data-grid-premium";
import React, { useState } from "react";
import { Await, Link as RouterLink, useLoaderData } from "react-router-dom";

import LoadingIndicator from "../../components/loading/LoadingIndicator";
import { useRootContext } from "../../RootLayout";
import { Account, AccountPermission, Status, User } from "../../services/openAPI/internal";
import { StatusTabOption } from "../../utils/dataUtils";
import { isDa } from "../../utils/permissionsUtil";
import { internalGrid, wrapperContainer } from "./styles";

const tabs = [
  { value: StatusTabOption.Active, label: "Active" },
  { value: StatusTabOption.Inactive, label: "Inactive" },
];

const activeUserStatus = Object.values(Status).filter((statusValue) => statusValue !== Status.Deleted);

const inactiveUserStatus = Object.values(Status).filter((statusValue) => statusValue === Status.Deleted);

export const ClientUsers = () => {
  const theme = useTheme();
  const { userInfo } = useRootContext() as {
    userInfo: UserInfo;
  };

  const NavLinkStyle = {
    color: theme.palette.primary.main,
    textDecoration: "none",
  };
  const changeTextDecorationUnderline = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.currentTarget.style.textDecoration = "underline";
  };

  const changeTextDecorationNone = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.currentTarget.style.textDecoration = "none";
  };
  const { users, account } = useLoaderData() as {
    users: User[];
    account: Account;
  };
  const [selectedTab, setSelectedTab] = useState<StatusTabOption>(StatusTabOption.Active);
  const [isTeamManagerView, setIsTeamManagerView] = useState<boolean>(false);

  const [filteredUsers, setFilteredOrgs] = useState(
    users.filter((user: User) => activeUserStatus.includes(user.status))
  );

  const filterActiveInactiveOrgs = (currTab: string) => {
    if (currTab === StatusTabOption.Active) {
      setFilteredOrgs(users.filter((user) => activeUserStatus.includes(user.status)));
    } else if (currTab === StatusTabOption.Inactive) {
      setFilteredOrgs(users.filter((user) => inactiveUserStatus.includes(user.status)));
    } else {
      setFilteredOrgs(users);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, value: string) => {
    setSelectedTab(value as StatusTabOption);
    filterActiveInactiveOrgs(value);
  };

  const handleTeamManagerView = () => {
    setIsTeamManagerView((prevState) => !prevState);
    setSelectedTab(StatusTabOption.Active);
    if (!isTeamManagerView && selectedTab === StatusTabOption.Active) {
      setFilteredOrgs(
        users.filter((user) =>
          user.accountPermissions?.activePermissions?.filter(
            (accountPerm) => accountPerm === AccountPermission.TeamManager
          )
        )
      );
    } else {
      setFilteredOrgs(users.filter((user) => activeUserStatus.includes(user.status)));
    }
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Grid container xs={12} spacing={2} justifyContent={"space-between"}>
          <Grid container xs={6} alignContent={"center"}>
            <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor={"secondary"}>
              {tabs.map((tab) => (
                <Tab key={tab.value} value={tab.value} label={tab.label}></Tab>
              ))}
            </Tabs>
            <FormControlLabel
              control={<Switch checked={isTeamManagerView} onChange={handleTeamManagerView} />}
              label="View Manager Only"
            />
          </Grid>
          {isDa(userInfo) && (
            <RouterLink to={`/clients/${account.id}/users/edit`}>
              <Button variant={"contained"} sx={{ mb: 1, mt: 1 }}>
                <SvgIcon component={Icons.PlusIcon} inheritViewBox sx={{ width: 20, height: 20, pr: 1 }} />
                New User
              </Button>
            </RouterLink>
          )}
        </Grid>
      </GridToolbarContainer>
    );
  };

  function getColorClass(value: string) {
    if (value.toUpperCase() === Status.PendingActivation || value.toUpperCase() === Status.PendingChanges) {
      return "client-status-orange-cell";
    } else {
      return "";
    }
  }

  const getEditLink = () => {
    return (
      <div>
        {selectedTab === StatusTabOption.Active ? (
          <RouterLink
            to={`/clients/${account.id}/users/edit`}
            style={NavLinkStyle}
            onMouseOver={changeTextDecorationUnderline}
            onMouseLeave={changeTextDecorationNone}
          >
            Edit
          </RouterLink>
        ) : (
          //TODO: Will be implemented once design is provided.
          <RouterLink
            to={`#`}
            style={NavLinkStyle}
            onMouseOver={changeTextDecorationUnderline}
            onMouseLeave={changeTextDecorationNone}
          >
            Activate
          </RouterLink>
        )}
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 40,
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "LAST NAME",
      minWidth: 50,
      flex: 1,
      valueGetter: (params: any) => {
        return params.row.name.lastName;
      },
    },

    {
      field: "firstName",
      headerName: "FIRST NAME",
      minWidth: 50,
      flex: 1,
      valueGetter: (params: any) => {
        return params.row.name.firstName;
      },
    },
    {
      field: "email",
      headerName: "EMAIL",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "phone",
      headerName: "PHONE",
      minWidth: 30,
      flex: 1,
      valueGetter: (params: any) => {
        return params.row.phone.countryCode + " " + params.row.phone.number;
      },
      //TODO: phone needs formatting with country code, need more direction.
      //valueGetter: (params) => format(params.value)
    },
    {
      field: "position",
      headerName: "POSITION",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "status",
      headerName: "STATUS",
      minWidth: 200,
      flex: 1,
      cellClassName: (params) => getColorClass(params.value),
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      minWidth: 120,
      align: "right",
      headerAlign: "right",
      renderCell: () => (isDa(userInfo) ? getEditLink() : <></>),
    },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        id: false,
      },
    },
  };

  const data = { columns, initialState, rows: filteredUsers };

  return (
    <>
      <Container maxWidth="xl" disableGutters sx={wrapperContainer} data-testid="ParentUsersContainer">
        <Grid container spacing={2}>
          <Grid xs={9}>
            <Typography variant="h3" sx={{ mb: 2.5 }}>
              Users
            </Typography>
          </Grid>
        </Grid>
        <Paper>
          <React.Suspense fallback={<LoadingIndicator />}>
            <Await resolve={users} errorElement={<Alert severity={"error"}>Error loading users data!</Alert>}>
              <Box sx={{ height: "100%", flex: 1, width: "100%" }}>
                <DataGridPremium
                  sx={internalGrid}
                  slots={{
                    toolbar: CustomToolbar,
                  }}
                  {...data}
                  initialState={{
                    ...data.initialState,
                  }}
                  hideFooter
                  hideFooterPagination
                />
              </Box>
            </Await>
          </React.Suspense>
        </Paper>
      </Container>
    </>
  );
};
