import { color, ThemeProvider, themes, TimeoutModal, useInterval, UserInfo } from "@bakkt/bakkt-ui-components";
import { Container } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import { LicenseInfo } from "@mui/x-license-pro";
import axios from "axios";
import { useEffect, useState } from "react";
import { defer, Outlet, useLoaderData, useOutletContext } from "react-router-dom";

import { Footer } from "./components/footer/Footer";
import { Header } from "./components/header/Header";
import { ClientFormData } from "./pages/clients/AddEditClient";
import { OrganizationFormData } from "./pages/organizations/AddEditOrganization";
import { UserFormData } from "./pages/users/AddEditUsers";
import {
  account,
  assets as assetsMockData,
  fetchMockDataPromiseWithDelay,
  getRandomizedPricingInfo,
  network as networkMockData,
  organizations,
  pricingInfoInit,
  userInfo as userInfoMock,
  users,
  wallets,
} from "./services/mockData.ts";
import { Asset, Network, PriceInfo, Status, Wallet, WalletType } from "./services/openAPI/internal";
import {
  AccountService,
  AssetService,
  OrganizationService,
  PriceService,
  UserService,
  WalletService,
} from "./services/serviceLoader";
import { WalletTemp } from "./utils/CryptoIconsMap.tsx";
import { AlertDisplay } from "./utils/customTypes.tsx";
import { API_URL, shouldUseMockData } from "./utils/dataUtils";
import { handleLogout } from "./utils/sessionUtils.ts";

LicenseInfo.setLicenseKey(
  "3ae214fa0120d6bf6b5d84afa5a9950dTz02NzAyNyxFPTE3MTYzOTc0OTYzMDQsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

export interface AccountMulti {
  account: ClientFormData | null;
  organizations: OrganizationFormData[];
  users: UserFormData[];
  isOrgEdited: boolean;
}

export type RootContextType = {
  userInfo: UserInfo;
  priceFeed: PriceInfo[];
  accountChanges: AccountMulti;
  setAccountChanges: React.Dispatch<React.SetStateAction<AccountMulti>>;
  alerts: AlertDisplay[];
  setAlerts: React.Dispatch<React.SetStateAction<AlertDisplay[]>>;
  addAlert: (alert: AlertDisplay) => void;
  shouldRefreshPolicyItems: boolean;
  setShouldRefreshPolicyItems: React.Dispatch<React.SetStateAction<boolean>>;
  assets: Asset[];
  networks: Network[];
  coldTrustFeeWallets: Wallet[];
};

// Defines max idle time for TimeoutModal
const maxIdleTimeInMinutes = 10;

export default function RootLayout() {
  const { userInfo, assets, networks, coldTrustFeeWallets } = useLoaderData() as {
    userInfo: UserInfo;
    assets: Asset[];
    networks: Network[];
    coldTrustFeeWallets: Wallet[];
  };
  const accountMulti = { account: null, organizations: [], users: [], isOrgEdited: false } as AccountMulti;
  const [accountChanges, setAccountChanges] = useState<AccountMulti>(accountMulti);
  const [alerts, setAlerts] = useState<AlertDisplay[]>([]);
  const [priceFeed, setPriceFeed] = useState<PriceInfo[]>(pricingInfoInit);
  const [shouldRefreshPolicyItems, setShouldRefreshPolicyItems] = useState<boolean>(false);

  const fetchPriceFeed = async () => {
    const feed: any = shouldUseMockData
      ? await fetchMockDataPromiseWithDelay(getRandomizedPricingInfo(), 2000)
      : await PriceService.getPrices();

    setPriceFeed(feed);
  };
  useEffect(() => {
    // Fetch Price Feed on initial render, then useInterval will continue updates below
    fetchPriceFeed();
  }, []);
  const refreshIntervalMilliseconds = 120000; // two minutes
  useInterval(async () => {
    fetchPriceFeed();
  }, refreshIntervalMilliseconds);

  function addAlert(alert: AlertDisplay) {
    setAlerts((prevAlerts) => {
      return [alert, ...prevAlerts];
    });
  }

  return (
    <ThemeProvider theme={themes.light}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: color.oysterGray.main,
          },
          ".body-links": {
            textDecoration: "underline",
            cursor: "pointer",
          },
          ".default-link-hover": {
            textDecoration: "none",
            cursor: "pointer",
            "&:hover": {
              color: `${color.energizingLime.main} !important`,
            },
          },
          ".content_wrapper": {
            padding: "32px 56px 32px 56px",
          },
          ".MuiContainer-maxWidthLg": {
            maxWidth: "3000px !important",
          },
          ".MuiDataGrid-root": {
            borderStyle: "none !important",
            ".MuiDataGrid-row:hover": { backgroundColor: "rgba(83,24,198,0.04) !important" },
          },
          ".client-status-orange-cell": {
            color: color.engagingOrange.dark,
          },
        }}
      />
      <Header />
      <Container maxWidth="xl" disableGutters className="content_wrapper">
        <Outlet
          context={{
            alerts,
            setAlerts,
            addAlert,
            userInfo,
            priceFeed,
            accountChanges,
            setAccountChanges,
            shouldRefreshPolicyItems,
            setShouldRefreshPolicyItems,
            assets,
            networks,
            coldTrustFeeWallets,
          }}
        />
      </Container>
      <Footer />

      <TimeoutModal handleLogout={handleLogout} maxIdleTimeInMinutes={maxIdleTimeInMinutes} />
    </ThemeProvider>
  );
}

export function useRootContext() {
  return useOutletContext<RootContextType>();
}

export async function loader() {
  const USER_INFO_URL = `${API_URL}/custody/userinfo`;
  const userInfo = shouldUseMockData
    ? ((await fetchMockDataPromiseWithDelay(userInfoMock, 500)) as UserInfo)
    : ((await axios.post(USER_INFO_URL)) as UserInfo);
  sessionStorage.setItem("USERINFO", JSON.stringify(userInfo));

  const accounts = shouldUseMockData
    ? ((await fetchMockDataPromiseWithDelay([account], 500)) as UserInfo)
    : await AccountService.getAccounts();

  const orgs = shouldUseMockData
    ? ((await fetchMockDataPromiseWithDelay(organizations, 500)) as UserInfo)
    : await OrganizationService.getOrganizations();

  const allUsers = shouldUseMockData ? await fetchMockDataPromiseWithDelay(users, 200) : await UserService.getUsers();

  const assets = shouldUseMockData
    ? await fetchMockDataPromiseWithDelay(assetsMockData, 300)
    : await AssetService.getAssets();

  const networks = shouldUseMockData
    ? await fetchMockDataPromiseWithDelay(networkMockData, 300)
    : await AssetService.getNetworks();

  const coldTrustFeeWallets = shouldUseMockData
    ? await fetchMockDataPromiseWithDelay(wallets, 300)
    : await WalletService.getWallets(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        WalletTemp.Cold,
        "Active",
        WalletType.Fee,
        "false",
        undefined
      );

  return defer({
    userInfo,
    accounts,
    orgs,
    allUsers,
    assets,
    networks,
    coldTrustFeeWallets,
  });
}
