import { formatCryptoQuantity, Icons, Link } from "@bakkt/bakkt-ui-components";
import { Box, IconButton, SvgIcon, Tooltip } from "@mui/material";
import { useState } from "react";

interface QuantityDisplayProps {
  quantity: number;
  ticker: string;
}

const QuantityDisplay = ({ quantity, ticker }: QuantityDisplayProps) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(String(formatCryptoQuantity(quantity, ticker, "long")));
  };

  const iconSx = { width: 10, color: "primary.contrastText" };

  return (
    <Tooltip
      title={
        open ? (
          `${formatCryptoQuantity(quantity, ticker, "long")} ${ticker} copied`
        ) : (
          <Box>
            <IconButton onClick={handleClick} sx={{ p: 0, m: 0.4 }} disableRipple disableFocusRipple>
              <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={iconSx} />
            </IconButton>
            {formatCryptoQuantity(quantity, ticker, "long")} {ticker}
          </Box>
        )
      }
      onMouseLeave={() => setOpen(false)}
      placement="top"
    >
      <span>{formatCryptoQuantity(quantity)}</span>
    </Tooltip>
  );
};

export default QuantityDisplay;
