import { Button, DialogTitle } from "@bakkt/bakkt-ui-components";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useGetCollateralRequest from "../../Hooks/useGetCollateralRequest";
import { RootContextType, useRootContext } from "../../RootLayout";
import { PolicyActionActionEnum } from "../../services/openAPI/internal";
import { isDa, isPolicyDisabled } from "../../utils/permissionsUtil";
import ReviewCollateralDetails from "./ReviewCollateralDetails";

const ReviewCollateralRequest = () => {
  const { userInfo } = useRootContext() as RootContextType;
  const navigate = useNavigate();
  const { state } = useLocation();
  const isApproved = state.approved;
  const policyItem = state.data;

  const { isLoading, collateralTransaction, orgWallets } = useGetCollateralRequest(policyItem);

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleDecline = () => {
    navigate(`/${PolicyActionActionEnum.Deny.toLowerCase()}-transaction/${policyItem.policyActionId}`, {
      state: policyItem,
    });
  };

  const handleConfirm = () => {
    navigate(`/${PolicyActionActionEnum.Approve.toLowerCase()}-transaction/${policyItem.policyActionId}`, {
      state: policyItem,
    });
  };

  const isDisabled = isPolicyDisabled(
    policyItem.policyInstanceStatus,
    policyItem.policySequenceStatus,
    userInfo,
    isApproved
  );

  const thirdActionSx = { selfAlign: "start", marginRight: "auto" };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth={false}>
        <DialogTitle title="Review Collateral Change" severity="warning">
          Organization has approved the below transaction.
        </DialogTitle>
        <DialogContent>
          <ReviewCollateralDetails
            collateralRequest={collateralTransaction}
            orgWallets={orgWallets}
            isLoading={isLoading}
          />
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"} onClick={handleClose} sx={thirdActionSx}>
            Go Back
          </Button>

          {isDa(userInfo) && (
            <>
              <Button variant={"outlined"} onClick={handleDecline} disabled={isDisabled} sx={{ mr: 1 }}>
                Decline
              </Button>

              <Button variant={"contained"} onClick={handleConfirm} disabled={isDisabled} autoFocus>
                Approve
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReviewCollateralRequest;
