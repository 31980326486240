import { Button, Dialog, DialogTitle } from "@bakkt/bakkt-ui-components";
import { DialogActions } from "@mui/material";

interface GoBackModalProps {
  onConfirmGoBack: () => void;
  goBack: boolean;
  setGoBack: React.Dispatch<React.SetStateAction<boolean>>;
}

const GoBackModal = ({ onConfirmGoBack, goBack, setGoBack }: GoBackModalProps) => {
  const handleStayHere = () => {
    setGoBack(false);
  };

  return (
    <Dialog open={goBack} onClose={handleStayHere} maxWidth="md">
      <DialogTitle title="Are you sure you want to go back?" severity="warning">
        You will lose your progress on the current screen if you go back
      </DialogTitle>
      <DialogActions>
        <Button variant={"outlined"} onClick={handleStayHere}>
          Stay Here
        </Button>
        <Button variant={"contained"} onClick={onConfirmGoBack} autoFocus>
          Go Back
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GoBackModal;
