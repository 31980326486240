import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  Icons,
  InputLabel,
  Select,
  TextField,
  Typography,
  UserInfo,
} from "@bakkt/bakkt-ui-components";
import {
  AlertTitle,
  Box,
  Checkbox,
  ClickAwayListener,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  SvgIcon,
  Tooltip,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  defer,
  LoaderFunctionArgs,
  useLoaderData,
  useLocation,
  useNavigate,
  useRouteLoaderData,
} from "react-router-dom";

import { AssetSVGIcon } from "../../components/CustomSVG/AssetSVGIcon";
import { allow, fetchMockDataPromiseWithDelay } from "../../services/mockData";
import {
  AllowlistAddress,
  AllowlistAddressStatusEnum,
  Asset,
  PolicyActionActionEnum,
  PolicyStatusEnum,
} from "../../services/openAPI/internal";
import { AllowlistService } from "../../services/serviceLoader";
import { iconsMap } from "../../utils/CryptoIconsMap";
import { getAllowlistById, shouldUseMockData, tickerToAssetEnum } from "../../utils/dataUtils";
import { isDa, isPolicyDisabled } from "../../utils/permissionsUtil";

const ReviewAllowlist = () => {
  const { userInfo, assets } = useRouteLoaderData("root") as {
    userInfo: UserInfo;
    assets: Asset[];
  };

  const navigate = useNavigate();
  const { state } = useLocation();
  const reviewItem = state;
  const { allowlistItem } = useLoaderData() as {
    allowlistItem: AllowlistAddress;
  };
  const [holdTimeRemainingInSec, setHoldTimeRemainingInSec] = useState<number>(0);
  const [holdTimeMessage, setHoldTimeMessage] = useState<string>("");
  const [open, setOpen] = useState<boolean>(true);
  const [showCopyAddressConfirm, setShowCopyAddressConfirm] = useState<boolean>(false);
  const [deviceReadyAffirmed, setDeviceReadyAffirmed] = useState<boolean>(false);
  const isApproved = state.approved;

  const timeDiff = (now: any, then: any) => {
    const diff = (now.getTime() - then.getTime()) / 1000;
    return Math.abs(Math.round(diff));
  };

  const checkFortyeightHourHold = () => {
    const now = new Date(Date.now());
    const then = new Date(state.data.timestamp);
    return timeDiff(now, then);
  };

  function convertToHourOrMinute(number: number): string {
    const result = number / 3600;
    if (result < 1) {
      return Math.ceil(result * 60) + " minute(s)";
    } else {
      return result.toFixed(0) + " hour(s)";
    }
  }

  useEffect(() => {
    const hold = checkFortyeightHourHold();
    if (hold < 48 * 3600) {
      setHoldTimeMessage(convertToHourOrMinute(48 * 3600 - hold));
      setHoldTimeRemainingInSec(48 * 3600 - hold);
    } else {
      setHoldTimeRemainingInSec(0);
    }
  }, []);

  const handleCopy = (address: string) => {
    navigator.clipboard.writeText(address);
    setShowCopyAddressConfirm(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleDecline = () => {
    navigate(`/${PolicyActionActionEnum.Deny.toLowerCase()}-allowlist/${reviewItem.data.policyActionId}`, {
      state: reviewItem,
    });
  };

  const handleApprove = () => {
    navigate(`/${PolicyActionActionEnum.Approve.toLowerCase()}-allowlist/${reviewItem.data.policyActionId}`, {
      state: reviewItem,
    });
  };

  const handleTooltipClose = () => {
    setShowCopyAddressConfirm(false);
  };

  // handle confirm device ready auth checkbox
  const handleDeviceReadyCheckbox = (): void => {
    setDeviceReadyAffirmed((prevState) => !prevState);
  };

  const thirdActionSx = { selfAlign: "start", marginRight: "auto" };

  const isExpiredAllowlist = allowlistItem.status === AllowlistAddressStatusEnum.Expired;

  const isApproveDisabled =
    isPolicyDisabled(
      reviewItem.data.policyInstanceStatus,
      reviewItem.data.policySequenceStatus,
      userInfo,
      isApproved
    ) ||
    isExpiredAllowlist ||
    (state.data.approvalCount === 0 && holdTimeRemainingInSec > 0) ||
    !deviceReadyAffirmed;

  const isDeclineDisabled = isPolicyDisabled(
    reviewItem.data.policyInstanceStatus,
    reviewItem.data.policySequenceStatus,
    userInfo,
    isApproved
  );

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth={false}>
      <DialogTitle title={"Review and Approve New Address"} severity="warning">
        Organization has approved the below address for Allowlisting. {holdTimeMessage}
      </DialogTitle>

      {state.data.approvalCount === 0 && holdTimeRemainingInSec > 0 ? (
        <Alert severity="info" sx={{ m: 4 }}>
          <AlertTitle>48hr Evaluation Hold</AlertTitle>
          This request may be approved in:
          <Box sx={{ pt: 2, mb: 2 }}>
            <Typography variant={"subtitle2"}>{holdTimeMessage + " remaining"}</Typography>
          </Box>
        </Alert>
      ) : (
        <Alert severity="error" sx={{ m: 4 }}>
          <AlertTitle>Signing Devices Ready</AlertTitle>
          <Box sx={{ pt: 2, mb: 2 }}>
            Allowlist operations approvals also require device signing and must be signed with urgency.
          </Box>
          <FormControlLabel
            control={<Checkbox checked={deviceReadyAffirmed} onChange={handleDeviceReadyCheckbox} />}
            label="Device Signers are ready"
          />
        </Alert>
      )}

      <DialogContent>
        <Grid container columnSpacing={3} rowSpacing={3} sx={{ pb: 2 }}>
          <Grid xs={6}>
            <TextField
              name="Client"
              value={reviewItem.data.client}
              label="Client"
              id="name"
              variant="standard"
              fullWidth
              disabled
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              name="address name"
              value={allowlistItem.name}
              label="Address Name"
              id="address name"
              variant="standard"
              fullWidth
              disabled
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              name="organization"
              value={reviewItem.data.organization}
              label="Organization"
              id="organization"
              variant="standard"
              fullWidth
              disabled
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              name="address"
              value={allowlistItem.address}
              label="Address"
              id="address"
              variant="standard"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <Tooltip
                        onClose={handleTooltipClose}
                        open={showCopyAddressConfirm}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        placement="top"
                        title="Address Copied"
                      >
                        <IconButton
                          onClick={() => handleCopy(String(allowlistItem.address))}
                          disableRipple
                          disableFocusRipple
                        >
                          <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={{ width: 15, height: 15 }} />
                        </IconButton>
                      </Tooltip>
                    </ClickAwayListener>
                  </InputAdornment>
                ),
              }}
              disabled
            />
          </Grid>
          <Grid container xs={12} alignItems={"flex-end"}>
            <Grid xs={6}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel id="select-asset" disabled>
                  Asset
                </InputLabel>
                <Select value={allowlistItem.assetTicker} label="asset" id="asset" disabled>
                  {assets.map((asset, index) => (
                    <MenuItem key={index} value={asset.ticker}>
                      <Grid container alignItems={"center"}>
                        <SvgIcon
                          component={() =>
                            AssetSVGIcon({
                              svgString: asset.iconSVG,
                              title: asset.ticker,
                              sx: { width: 32, height: 32, pr: 1 },
                            })
                          }
                        />
                        <Typography sx={{ mr: 1 }}>{asset.name}</Typography>
                        <Typography variant={"body2"}>{asset.ticker}</Typography>
                      </Grid>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <TextField
                name="centralized provider name"
                value={allowlistItem.financialInstitutionName}
                label="Centralized Provider Name"
                id="centralized provider name"
                variant="standard"
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant={"outlined"} onClick={handleClose} sx={thirdActionSx}>
          Go Back
        </Button>
        {isDa(userInfo) && (
          <>
            <Button variant={"outlined"} onClick={handleDecline} disabled={isDeclineDisabled}>
              Decline
            </Button>
            <Button variant={"contained"} onClick={handleApprove} autoFocus disabled={isApproveDisabled}>
              Approve
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ReviewAllowlist;

export async function loader({ params }: LoaderFunctionArgs) {
  const allowlistId = Number(params.allowlistId);

  const allowlistItem = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(getAllowlistById(allowlistId, allow) || {}, 300)
    : AllowlistService.getAllowlistAddressById(allowlistId);

  return defer({
    allowlistItem: await allowlistItem,
  });
}
