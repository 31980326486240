import { Alert, Button, Dialog, DialogTitle, Skeleton, Typography, UserInfo } from "@bakkt/bakkt-ui-components";
import {
  AlertTitle,
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Unstable_Grid2 as Grid,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useFetcher, useLocation, useNavigate, useRouteLoaderData } from "react-router-dom";

import useGetSettlementDetails, { SettlementFormData } from "../../Hooks/useGetSettlementDetails";
import { WalletTransaction } from "../../services/openAPI/internal";
import { formatCurrentDate } from "../../utils/dataUtils";
import { isPolicyDisabled } from "../../utils/permissionsUtil";
import ReviewSettlementLineItem, { SettlementLineItem } from "./ReviewSettlementLineItem";

const ReviewSettlement = () => {
  const { userInfo } = useRouteLoaderData("root") as {
    userInfo: UserInfo;
  };
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const { state } = useLocation();
  const theme = useTheme();
  const settlementItem = state.data;
  const isApproved = state.approved;

  const { settlementTransaction, isLoading } = useGetSettlementDetails(settlementItem);

  const [open, setOpen] = useState(true);
  const [deviceReadyAffirmed, setDeviceReadyAffirmed] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleAcknowledge = () => {
    fetcher.submit(JSON.stringify("acknowledge"), {
      method: "post",
      encType: "application/json",
    });
    navigate(`/`);
  };

  const handleDeviceReadyCheckbox = (): void => {
    setDeviceReadyAffirmed((prevState) => !prevState);
  };

  const isApproveDisabled =
    isPolicyDisabled(settlementItem.policyInstanceStatus, settlementItem.policySequenceStatus, userInfo, isApproved) ||
    !deviceReadyAffirmed;

  function buildSettlementLineItem(
    settlementFormData: Partial<SettlementFormData>,
    transaction: WalletTransaction
  ): SettlementLineItem {
    return {
      organizationId: settlementFormData.organizationId || 0,
      clientName: settlementFormData.clientName || "",
      orgName: settlementFormData.orgName || "",
      requesterName: settlementFormData.requesterName || "",
      createdOn: settlementFormData.createdOn || 0,
      settlementTransaction: transaction,
    };
  }

  const shouldMapOverSettlements =
    settlementTransaction && (settlementTransaction?.settlementTransactions?.length as number) > 0;

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth={false}>
        <DialogTitle title={`Acknowledge Settlement`} severity="warning">
          Acknowledge organization's settlement details below.
        </DialogTitle>
        {isLoading && <Skeleton variant="rectangular" height={110} />}
        {!isLoading && (
          <>
            <Alert severity="error" sx={{ m: 4 }}>
              <AlertTitle>Signing Devices Ready</AlertTitle>
              <Box sx={{ pt: 2, mb: 2 }}>
                Settlement will occur within a 2 hour window of <strong>8:30am UTC on {formatCurrentDate()}</strong>.
                Settlement operations approvals also require device signing and must be signed with urgency.
              </Box>
              <FormControlLabel
                control={<Checkbox checked={deviceReadyAffirmed} disableRipple />}
                onChange={handleDeviceReadyCheckbox}
                label="Device Signers are ready"
              />
            </Alert>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid xs={12}>
                  <Typography sx={{ fontWeight: 600, mb: 1 }}>Change</Typography>
                  <Grid>
                    {shouldMapOverSettlements &&
                      settlementTransaction?.settlementTransactions?.map(
                        (transaction: WalletTransaction, index: number) => (
                          <ReviewSettlementLineItem
                            key={index}
                            settlementLineItem={buildSettlementLineItem(settlementTransaction, transaction)}
                          />
                        )
                      )}
                  </Grid>
                  <Grid>
                    <Grid sx={{ borderBottom: 1, borderColor: theme.palette.divider, mb: 2 }}>
                      <Typography sx={{ fontWeight: 600, mb: 1 }}>Updated Balances</Typography>
                      <Grid container justifyContent={"space-between"} sx={{ mb: 3 }}>
                        <Grid>
                          <Typography variant="subtitle2">AVAILABLE BALANCE</Typography>
                        </Grid>
                        <Grid direction="row" sx={{ textAlign: "right" }}>
                          <Typography variant="subtitle1">0 USD</Typography>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent={"space-between"} sx={{ mb: 3 }}>
                        <Grid>
                          <Typography variant="subtitle2">COLLATERAL BALANCE</Typography>
                        </Grid>
                        <Grid direction="row" sx={{ textAlign: "right" }}>
                          <Typography variant="subtitle1">0 USD</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent={"space-between"}>
                      <Grid>
                        <Typography variant="subtitle2">NET CHANGE</Typography>
                      </Grid>
                      <Grid direction="row" sx={{ textAlign: "right" }}>
                        <Typography variant="subtitle1">0 USD</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container justifyContent={"flex-end"}>
                <Button variant={"contained"} onClick={handleClose} sx={{ mr: 2 }}>
                  Cancel
                </Button>

                <Button variant={"contained"} onClick={handleAcknowledge} disabled={isApproveDisabled} autoFocus>
                  Acknowledge Settlement
                </Button>
              </Grid>
            </DialogActions>
            )
          </>
        )}
      </Dialog>
    </>
  );
};

export default ReviewSettlement;
