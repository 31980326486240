import { useEffect, useState } from "react";

import { fetchMockDataPromiseWithDelay, walletTransactions } from "../services/mockData";
import { PolicyActionDetails, WalletTransaction } from "../services/openAPI/internal";
import { WalletTransactionService } from "../services/serviceLoader";
import { shouldUseMockData } from "../utils/dataUtils";

export interface SettlementFormData {
  organizationId: number;
  clientName: string;
  orgName: string;
  requesterName: string;
  createdOn: number;
  settlementTransactions: WalletTransaction[];
}

const useGetSettlementDetails = (settlementItem: PolicyActionDetails) => {
  const [settlementTransaction, setSettlementTransaction] = useState<Partial<SettlementFormData>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const orgId = Number(JSON.parse(String(settlementItem.policyInstancePayload)).organizationId);

  const getSettlementTransactionsDetails = async () => {
    // get all transactions by orgId
    const orgWalletTransactions: WalletTransaction[] = shouldUseMockData
      ? ((await fetchMockDataPromiseWithDelay(walletTransactions, 300)) as any)
      : ((await WalletTransactionService.getWalletTransactions(undefined, orgId)) as any);

    const settlementTransactions = orgWalletTransactions.filter((tx) =>
      JSON.parse(String(settlementItem.policyInstancePayload))?.transactionIds?.includes(tx.walletTransactionId || 0)
    );

    const settlementFormData: Partial<SettlementFormData> = {
      organizationId: orgId,
      createdOn: settlementItem.timestamp,
      clientName: settlementItem.client,
      orgName: settlementItem.organization,
      requesterName: settlementItem.requester,
      settlementTransactions: settlementTransactions,
    };

    setSettlementTransaction(settlementFormData);
    setIsLoading(false);
  };

  useEffect(() => {
    getSettlementTransactionsDetails();
  }, []);

  return { settlementTransaction, isLoading };
};

export default useGetSettlementDetails;
