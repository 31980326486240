import { Paper, Typography } from "@bakkt/bakkt-ui-components";
import { Container, Unstable_Grid2 as Grid } from "@mui/material";
import React from "react";

import { innerGridContainer, subText, wrapperContainer } from "./styles.tsx";
import { WalletsGrid } from "./WalletsGrid.tsx";

export default function ClientWallets() {
  return (
    <Container maxWidth="xl" disableGutters sx={wrapperContainer}>
      <Typography variant="h3" data-testid="wallets">
        Wallets
      </Typography>
      <Typography variant="body1" sx={subText}></Typography>

      <Paper>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Container sx={innerGridContainer} data-testid="walletsTable">
              <WalletsGrid />
            </Container>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
