import { formatUnixTime } from "@bakkt/bakkt-ui-components";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import * as Papa from "papaparse";

import { ClientFormData, ClientPhone } from "../pages/clients/AddEditClient";
import { OrganizationFormData } from "../pages/organizations/AddEditOrganization";
import { UserFormData, UserOrgPermission } from "../pages/users/AddEditUsers";
import { organizations } from "../services/mockData";
import {
  Account,
  AccountLevelPermission,
  AccountPermission,
  AccountRequest,
  AllowlistAddress,
  Asset,
  AssetType,
  Network,
  Organization,
  OrganizationLevelPermission,
  OrganizationRequest,
  PriceInfo,
  Role,
  Status,
  User,
  UserRequest,
  Wallet,
  WalletTransaction,
  WalletType,
} from "../services/openAPI/internal";
import { EnvType } from "./customTypes.tsx";

export const shouldUseMockData = import.meta.env.VITE_USE_MOCK_DATA === "true";
//if token has not yet been replaced by CI/CD process, i.e. you are running locally, use vite env variable
export const API_URL =
  (window as any)["API_URL"] == "{{API_URL}}" ? import.meta.env.VITE_API_URL : (window as any)["API_URL"];
export const BLOCKCYPHER_URL =
  (window as any)["BLOCKCYPHER_URL"] == "{{BLOCKCYPHER_URL}}"
    ? import.meta.env.VITE_BLOCKCYPHER_URL
    : (window as any)["BLOCKCYPHER_URL"];
export const ETHERSCAN_URL =
  (window as any)["ETHERSCAN_URL"] == "{{ETHERSCAN_URL}}"
    ? import.meta.env.VITE_ETHERSCAN_URL
    : (window as any)["ETHERSCAN_URL"];
export const BCH_EXPLORER_URL =
  (window as any)["BCH_EXPLORER_URL"] == "{{BCH_EXPLORER_URL}}"
    ? import.meta.env.VITE_BCH_EXPLORER_URL
    : (window as any)["BCH_EXPLORER_URL"];
export const LTC_EXPLORER_URL =
  (window as any)["LTC_EXPLORER_URL"] == "{{LTC_EXPLORER_URL}}"
    ? import.meta.env.VITE_LTC_EXPLORER_URL
    : (window as any)["LTC_EXPLORER_URL"];
export const DOGE_EXPLORER_URL =
  (window as any)["DOGE_EXPLORER_URL"] == "{{DOGE_EXPLORER_URL}}"
    ? import.meta.env.VITE_DOGE_EXPLORER_URL
    : (window as any)["DOGE_EXPLORER_URL"];
export const environment: string = API_URL === "https://api.custody.bakkt.com" ? EnvType.Mainnet : EnvType.Testnet;

export function capitalizeFirst(str: string) {
  let result = "";
  if (typeof str === "string") {
    result = str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    result = str;
  }
  return result;
}

export function convertPricingInfoArrayIntoMap(pricingInfoArray: PriceInfo[]) {
  const uniqueTickers = [...new Set(pricingInfoArray.map((priceInfo) => priceInfo.assetTicker || ""))];
  const pricingInfoMap: Record<string, PriceInfo> = {};
  //Add CHL when in lowers
  if (environment !== EnvType.Mainnet && !uniqueTickers.includes("CHL")) {
    uniqueTickers.push("CHL");
  }
  uniqueTickers.map((uniqueTicker) => {
    pricingInfoMap[uniqueTicker] = pricingInfoArray.find((priceInfo) => priceInfo.assetTicker === uniqueTicker) || {};
    if (uniqueTicker === "CHL") {
      pricingInfoMap[uniqueTicker] = pricingInfoArray.find((priceInfo) => priceInfo.assetTicker === "USDC") || {};
    } else {
      pricingInfoMap[uniqueTicker] = pricingInfoArray.find((priceInfo) => priceInfo.assetTicker === uniqueTicker) || {};
    }
  });
  return pricingInfoMap;
}

export interface BalanceSummary {
  total: number;
  warm: number;
  cold: number;
}

export interface QuarantineSummary {
  quarantine: number;
}

//TODO: Continue building out this enum if is not provided from backend
export const tickerToAssetEnum: Record<string, string> = {
  BTC: "Bitcoin",
  ETH: "Ethereum",
  LTC: "Litecoin",
  ETC: "Ethereum Classic",
  BCH: "Bitcoin Cash",
  DOGE: "Dogecoin",
  SHIB: "Shiba Inu",
  USDC: "USD Coin",
  CHL: "Chyle Token",
};

interface CSVFile {
  CSVContent: string;
  fileName: string;
}

export function getQuarantineWalletBalance(wallets: Wallet[], pricingInfo: PriceInfo[]): QuarantineSummary {
  const pricingInfoMap = convertPricingInfoArrayIntoMap(pricingInfo);
  const uniqueTickers = [...new Set(wallets.map((wallet) => wallet.assetTicker || ""))];
  const walletMap: Record<string, Wallet[]> = {};
  uniqueTickers.map((uniqueTicker) => {
    walletMap[uniqueTicker] = wallets.filter((wallet) => wallet.assetTicker === uniqueTicker);
  });

  let totalQuarantineBalance = 0;
  for (const walletKey in walletMap) {
    const pricingInfoBidPrice = pricingInfoMap[walletKey]?.bidPrice || 0;

    const quarantineQuantity = walletMap[walletKey].reduce((sum, cur) => {
      return sum + Number(cur.quantity);
    }, 0);

    totalQuarantineBalance += quarantineQuantity * pricingInfoBidPrice;
  }

  return { quarantine: totalQuarantineBalance };
}

export function calculateBalanceTotalsFromWallets(wallets: Wallet[], pricingInfo: PriceInfo[]): BalanceSummary {
  const pricingInfoMap = convertPricingInfoArrayIntoMap(pricingInfo);
  const uniqueTickers = [...new Set(wallets.map((wallet) => wallet.assetTicker || ""))];
  const walletMap: Record<string, Wallet[]> = {};
  uniqueTickers.map((uniqueTicker) => {
    walletMap[uniqueTicker] = wallets.filter((wallet) => wallet.assetTicker === uniqueTicker);
  });

  let totalBalance = 0;
  let totalWarmBalance = 0;
  let totalColdBalance = 0;
  for (const walletKey in walletMap) {
    const pricingInfoBidPrice = pricingInfoMap[walletKey]?.bidPrice || 0;
    const quantity = walletMap[walletKey].reduce((sum, cur) => {
      return sum + Number(cur.quantity);
    }, 0);
    const warmQuantity = walletMap[walletKey].reduce((sum, cur) => {
      if (cur.temperature === "Hot" || cur.temperature === "Warm") {
        return sum + Number(cur.quantity);
      } else {
        return sum;
      }
    }, 0);
    const coldQuantity = walletMap[walletKey].reduce((sum, cur) => {
      if (cur.temperature === "Cold") {
        return sum + Number(cur.quantity);
      } else {
        return sum;
      }
    }, 0);

    totalBalance += quantity * pricingInfoBidPrice;
    totalWarmBalance += warmQuantity * pricingInfoBidPrice;
    totalColdBalance += coldQuantity * pricingInfoBidPrice;
  }

  return { total: totalBalance, warm: totalWarmBalance, cold: totalColdBalance };
}

export function deriveWalletDetailsFromWallets(wallets: Wallet[]) {
  const uniqueTickers = [...new Set(wallets.map((wallet) => wallet.assetTicker || ""))];
  const cryptoWalletMap: Record<string, Wallet[]> = {};
  uniqueTickers.map((uniqueTicker) => {
    cryptoWalletMap[uniqueTicker] = wallets.filter((wallet) => wallet.assetTicker === uniqueTicker);
  });
  return cryptoWalletMap;
}

export interface WalletSummary {
  asset: string;
  assetSymbol: string;
  percentChange: number;
  marketPrice: number;
  quantity: number;
  balance: number;
  actions: string;
}

export function deriveWalletSummariesFromWallets(wallets: Wallet[], pricingInfo: PriceInfo[]) {
  const pricingInfoMap = convertPricingInfoArrayIntoMap(pricingInfo);
  const cryptoWalletMap = deriveWalletDetailsFromWallets(wallets);
  const cryptoWalletSummary: WalletSummary[] = [];
  for (const cryptoWalletKey in cryptoWalletMap) {
    const quantity = cryptoWalletMap[cryptoWalletKey].reduce((sum, cur) => sum + Number(cur.quantity), 0);
    cryptoWalletSummary.push({
      asset: tickerToAssetEnum[cryptoWalletKey],
      assetSymbol: cryptoWalletKey,
      percentChange: 0,
      marketPrice: pricingInfoMap[cryptoWalletKey]?.bidPrice || 0,
      quantity: quantity,
      balance: (pricingInfoMap[cryptoWalletKey]?.bidPrice || 0) * quantity,
      actions: "New Wallet",
    });
  }
  return cryptoWalletSummary;
}

export function getAccount(clientId: number, accounts: Account[]): Account | undefined {
  return accounts.find((account) => account.id === clientId);
}

export function getOrgs(clientId: number, organizations: Organization[]): Organization[] {
  return organizations.filter((org) => org.accountId === clientId);
}

export function getUsersByAccountId(clientId: number, users: User[]): User[] {
  return users.filter((user) => user.accountId === clientId);
}

//Returns all users who have at least one permission to that org.
export function getUsersByOrgId(orgId: number, users: User[]): User[] {
  return users.filter((user) => isOrgFound(orgId, user.organizations));
}

function isOrgFound(orgId: number, orgLevelPerms: OrganizationLevelPermission[] | null | undefined): boolean {
  const result = orgLevelPerms?.some((perm) => perm.organizationId === orgId);
  return result || false;
}

function isUserActive(user: User, activeUserStatus: string[]) {
  return activeUserStatus.includes(user.status);
}

//Returns all Active(based on array of user status) users who have at lease one permission to that org.
export function getActiveUsersByOrgId(users: User[], orgId: number, activeUserStatus: string[]): User[] {
  return users.filter((user) => isOrgFound(orgId, user.organizations) && isUserActive(user, activeUserStatus));
}

export function getOrganizations(orgIds: number[], orgs: Organization[]): Organization[] {
  return organizations.filter((org) => orgIds.includes(org.id));
}

export function mapClientDataToForm(account: Account | null, isUpdateMode: boolean): ClientFormData {
  if (isUpdateMode && account !== null) {
    return {
      id: account.id,
      name: account.name,
      street1: account.address.street1,
      street2: account.address.street2,
      city: account.address.city,
      state: account.address.state,
      zipCode: account.address.zipCode,
      country: account.address.country,
      phone: {
        countryCode: account.phone.countryCode,
        number: account.phone.number,
      },
      inputPhone: account.phone.countryCode + "" + account.phone.number,
    } as ClientFormData;
  } else {
    return {
      id: -1,
      name: "",
      street1: "",
      street2: null,
      city: "",
      state: "",
      zipCode: "",
      country: "",
      phone: {
        countryCode: "",
        number: "",
      } as ClientPhone,
      inputPhone: "",
    } as ClientFormData;
  }
}

export function mapOrganizationDataToForm(
  organizations: Organization[] | null,
  isUpdateMode: boolean,
  accountId?: number
): OrganizationFormData[] {
  if (isUpdateMode && organizations !== null && organizations.length > 0) {
    return organizations.map((org) => {
      return {
        id: org.id,
        name: org.name,
        accountId: org.accountId,
        withdrawalAuthorization: {
          videoAuthorization: org.withdrawalAuthorization.videoAuthorization,
          amountLimit: org.withdrawalAuthorization.amountLimit,
        },
        numberOfConsensus: org.numberOfConsensus,
      };
    }) as OrganizationFormData[];
  } else {
    return [
      {
        id: -1,
        name: "",
        accountId: accountId ? accountId : -1,
        withdrawalAuthorization: {
          videoAuthorization: false,
          amountLimit: 0,
        },
        numberOfConsensus: 0,
      },
    ] as OrganizationFormData[];
  }
}

export function mapUserDataToForm(users: User[] | null, isUpdateMode: boolean): UserFormData[] {
  if (isUpdateMode && users !== null && users.length > 0) {
    return users.map((user) => {
      return {
        id: user.id,
        firstName: user.name.firstName,
        lastName: user.name.lastName,
        email: user.email,
        inputPhoneNumber: user.phone.countryCode + user.phone.number,
        phoneCountryCode: user.phone.countryCode,
        nationalPhoneNumber: user.phone.number,
        position: user.position,
        organizations: user.organizations?.map((orgPerms) => {
          return {
            organizationId: orgPerms.organizationId,
            permissions: orgPerms.activePermissions,
          };
        }),
        isTeamManager: user.accountPermissions?.activePermissions?.length === 1 ? true : false,
        accountId: user.accountId,
      };
    }) as UserFormData[];
  } else {
    return [
      {
        id: -1,
        firstName: "",
        lastName: "",
        email: "",
        inputPhoneNumber: "",
        phoneCountryCode: "",
        nationalPhoneNumber: "",
        position: "",
        organizations: [],
        isTeamManager: false,
        accountId: -1,
      },
    ] as UserFormData[];
  }
}

export function mapClientFormDataToAccountRequest(clientFormData: ClientFormData): AccountRequest {
  return {
    name: clientFormData.name,
    address: {
      street1: clientFormData.street1,
      street2: clientFormData.street2,
      city: clientFormData.city,
      state: clientFormData.state,
      zipCode: clientFormData.zipCode,
      country: clientFormData.country,
    },
    phone: {
      countryCode: clientFormData.phone.countryCode,
      number: clientFormData.phone.number,
    },
  } as AccountRequest;
}

export function mapOrgFormDataToOrganizationRequest(
  orgFormData: OrganizationFormData[],
  accountId?: number
): OrganizationRequest[] {
  return orgFormData.map((org) => {
    return {
      name: org.name,
      accountId: accountId ? accountId : org.accountId,
      withdrawalAuthorization: {
        videoAuthorization: org.withdrawalAuthorization.videoAuthorization,
        amountLimit: org.withdrawalAuthorization.amountLimit,
      },
      numberOfConsensus: org.numberOfConsensus,
    };
  });
}

export function mapUserFormDataToUserRequest(usersFormData: UserFormData[], accountId?: number): UserRequest[] {
  return usersFormData.map((userFormData) => {
    return {
      name: {
        firstName: userFormData.firstName,
        lastName: userFormData.lastName,
      },
      position: userFormData.position,
      accountId: accountId ? accountId : userFormData.accountId,
      userRole: { roles: [Role.Client] },
      email: userFormData.email,
      phone: {
        countryCode: userFormData.phoneCountryCode,
        number: userFormData.nationalPhoneNumber,
      },
      organizations: transformPermissions(userFormData.organizations),
      accountPermissions: transformAccountPermissions(userFormData.isTeamManager),
    };
  });
}

export function mapUserFormDataToUser(userFormData: UserFormData, accountId: number): User {
  return {
    id: userFormData.id,
    name: {
      firstName: userFormData.firstName,
      middleName: "",
      lastName: userFormData.lastName,
    },
    position: userFormData.position,
    accountId: accountId,
    userRole: { roles: [Role.Client] },
    email: userFormData.email,
    phone: {
      countryCode: userFormData.phoneCountryCode,
      number: userFormData.nationalPhoneNumber,
    },
    organizations: transformPermissions(userFormData.organizations),
    accountPermissions: transformAccountPermissions(userFormData.isTeamManager),
    status: Status.Active,
  } as User;
}

export function mapOrgFormDataToOrganization(orgFormData: OrganizationFormData[]): Organization[] {
  return orgFormData.map((org) => {
    return {
      id: org.id,
      status: Status.Active,
      name: org.name,
      accountId: org.accountId,
      withdrawalAuthorization: {
        videoAuthorization: org.withdrawalAuthorization.videoAuthorization,
        amountLimit: org.withdrawalAuthorization.amountLimit,
      },
      numberOfConsensus: org.numberOfConsensus,
    };
  }) as Organization[];
}

function transformPermissions(userPermissions: UserOrgPermission[]): OrganizationLevelPermission[] {
  //permissions[] are needed for form display, but we shouldn't send a UserOrgPermission object when the permissions array inside is empty.
  return userPermissions
    .filter((orgPerm) => orgPerm.permissions?.length > 0)
    .map((userPermission) => {
      return {
        organizationId: userPermission.organizationId,
        activePermissions: userPermission.permissions.map((perm: string) => perm),
      } as OrganizationLevelPermission;
    });
}

function transformAccountPermissions(isTeamManager: boolean): AccountLevelPermission {
  return {
    activePermissions: isTeamManager ? [AccountPermission.TeamManager] : [],
  } as AccountLevelPermission;
}

export function areAccountsEqual(newValue: ClientFormData, oldValue: Account) {
  if (
    newValue.id === oldValue.id &&
    newValue.name === oldValue.name &&
    newValue.street1 === oldValue.address.street1 &&
    newValue.street2 === oldValue.address.street2 &&
    newValue.city === oldValue.address.city &&
    newValue.state === oldValue.address.state &&
    newValue.zipCode === oldValue.address.zipCode &&
    newValue.country === oldValue.address.country &&
    newValue.phone.number === oldValue.phone.number &&
    newValue.phone.countryCode === oldValue.phone.countryCode
  ) {
    return true;
  }
  return false;
}

export function areOrganizationsEqual(newValue: OrganizationFormData, oldValue: Organization) {
  if (
    (isObjectDefined(newValue) && !isObjectDefined(oldValue)) ||
    (!isObjectDefined(newValue) && isObjectDefined(oldValue))
  ) {
    return false;
  }
  if (
    newValue.id === oldValue.id &&
    newValue.name === oldValue.name &&
    newValue.accountId === oldValue.accountId &&
    newValue.withdrawalAuthorization.videoAuthorization === oldValue.withdrawalAuthorization.videoAuthorization &&
    newValue.withdrawalAuthorization.amountLimit === oldValue.withdrawalAuthorization.amountLimit &&
    newValue.numberOfConsensus === oldValue.numberOfConsensus
  ) {
    return true;
  }
  return false;
}

function isObjectDefined(obj: any): boolean {
  return obj !== null && obj !== undefined;
}

function areOrgPermissionsEqual(
  newValue: UserOrgPermission[],
  oldValue: Array<OrganizationLevelPermission> | null | undefined
) {
  for (let i = 0; i < newValue.length; i++) {
    const oldPerm = oldValue?.find((oldPerm) => oldPerm.organizationId === newValue[i].organizationId) || null;
    //newPerms currently have OrganizationLevelPermission that has empty Permissions array, but existing user will not have the OrganizationLevelPermission.
    if (oldPerm === null && newValue[i].permissions.length > 0) {
      return false;
    }
    if (oldPerm !== null && newValue[i].permissions.length !== oldPerm.activePermissions?.length) {
      return false;
    }
    const newPerms = newValue[i].permissions;
    if (oldPerm !== null) {
      newPerms.map((newPerm) => {
        if (!oldPerm.activePermissions?.find((oldP: string) => oldP === newPerm)) {
          return false;
        }
      });
    }
  }
  return true;
}

function areTeamManagersEqual(isTeamManager: boolean, accountPermissions: AccountLevelPermission | null | undefined) {
  const oldAccountPermission = accountPermissions?.activePermissions?.length || 0;
  if ((isTeamManager && oldAccountPermission === 0) || (!isTeamManager && oldAccountPermission > 0)) {
    return false;
  }
  return true;
}

export function areUsersEqual(newValue: UserFormData, oldValue: User) {
  if (
    newValue.id === oldValue.id &&
    newValue.firstName === oldValue.name.firstName &&
    newValue.lastName === oldValue.name.lastName &&
    newValue.email === oldValue.email &&
    newValue.nationalPhoneNumber === oldValue.phone.number &&
    newValue.phoneCountryCode === oldValue.phone.countryCode &&
    newValue.position === oldValue.position &&
    oldValue.accountId === newValue.accountId &&
    areOrgPermissionsEqual(newValue.organizations, oldValue.organizations) &&
    areTeamManagersEqual(newValue.isTeamManager, oldValue.accountPermissions)
  ) {
    return true;
  }
  return false;
}

export enum StatusTabOption {
  Active = "active",
  Inactive = "inactive",
}

export function getAllowlistById(id: number, allowlist: AllowlistAddress[]): AllowlistAddress | undefined {
  return allowlist?.find((allowlist) => allowlist.id === id);
}

export interface AvailableAndPendingBalanceSummary {
  totalBalanceCrypto: number;
  availableBalanceCrypto: number;
  pendingBalanceCrypto: number;
  totalBalanceUsd: number;
  availableBalanceUsd: number;
  pendingBalanceUsd: number;
}

export const getAvailableAndPendingBalances = (
  wallet: Wallet | undefined | null,
  pricingInfo: PriceInfo[]
): AvailableAndPendingBalanceSummary => {
  const ticker = wallet?.assetTicker || "";
  const pricingInfoMap = convertPricingInfoArrayIntoMap(pricingInfo);
  const bidPrice = pricingInfoMap[ticker]?.bidPrice || 0;

  const total = wallet?.quantity || 0;
  const available = wallet?.availableBalance || 0;
  const pending = wallet?.pendingBalance || 0;

  return {
    totalBalanceCrypto: total,
    availableBalanceCrypto: available,
    pendingBalanceCrypto: pending,
    totalBalanceUsd: total * bidPrice,
    availableBalanceUsd: available * bidPrice,
    pendingBalanceUsd: pending * bidPrice,
  };
};

export const getPriceByAssetQty = (ticker: string, qty: number, pricingInfo: PriceInfo[]) => {
  const pricingInfoMap = convertPricingInfoArrayIntoMap(pricingInfo);
  const bidPrice = pricingInfoMap[ticker].bidPrice || 0;

  return qty * bidPrice;
};

export function isAssetToken(assetSymbol: string, assets: Asset[]): boolean {
  const asset = assets.find((asset) => asset.symbol === assetSymbol);
  if (asset && asset.type === AssetType.Token) {
    return true;
  }
  return false;
}

function compareWalletsById(wallet1: Wallet, wallet2: Wallet): number {
  const id1 = wallet1.walletId !== undefined ? wallet1.walletId : Number.MAX_SAFE_INTEGER;
  const id2 = wallet2.walletId !== undefined ? wallet2.walletId : Number.MAX_SAFE_INTEGER;
  return id1 - id2;
}

export function getGasBalanceForFeeWallet(wallet: Wallet | null, priceFeed: PriceInfo[]) {
  function getWalletBalances(): AvailableAndPendingBalanceSummary {
    return getAvailableAndPendingBalances(wallet, priceFeed);
  }
  const balances = getWalletBalances();
  return balances.availableBalanceCrypto;
}

/**
 * Prepares and downloads a CSV file from an input array of strings, each element corresponds to a row in a CSV.
 * @param inputData: an array of rows and each row has "," as column separator
 * @param accountName
 */
export function generateCsv(inputData: string[]): CSVFile {
  let fileName = "";

  function getCSVFileName(CSVData: string[][]) {
    //Since first row is header row, length has to be at least 2 to have accountName
    if (CSVData.length <= 1) {
      return "";
    } else {
      return (
        CSVData[1][2] + " Balance Report " + formatUnixTime(new Date().getTime(), "long").replace(":", " ") + ".csv"
      );
    }
  }

  try {
    const data = inputData.map((row) => row).join("\n");
    let csvString = "";

    Papa.parse(data, {
      header: false,
      complete: (result) => {
        const parsedData = result.data as string[][];

        //get accountName to generate fileName from payload data
        fileName = getCSVFileName(parsedData);

        //Convert Parsed data to CSV
        csvString = Papa.unparse(parsedData);
      },
      error: (error: Error) => {
        console.log("Error parsing CSV", error);
      },
    });

    return {
      CSVContent: csvString,
      fileName: fileName,
    };
  } catch (error) {
    console.error("Error processing data:", error);
    throw new Error("Error processing data: " + error);
  }
}

export function generateAndDownloadZip(inputData: string[][]) {
  const zip = new JSZip();

  //iterate through each client's report and add the CSV file to zip
  inputData.forEach((reportInput) => {
    try {
      const CSVFile = generateCsv(reportInput);
      zip.file(CSVFile.fileName, CSVFile.CSVContent);
    } catch (error) {
      console.error("Error processing data:", error);
    }
  });
  zip.generateAsync({ type: "blob" }).then((content) => {
    saveAs(content, "All Clients Balance Report.zip");
  });
}

export const mapFauxIds = (list: WalletTransaction[]) => {
  return list.map((item, i) => {
    return {
      ...item,
      id: i,
    };
  });
};

export const getScanLink = (ticker: string, walletAddress: string, assets: Asset[], networks: Network[]) => {
  const walletAddressAsset = assets.find((asset) => asset.ticker === ticker);
  const walletAddressNetwork = networks.find((network) => network.name === walletAddressAsset?.network);
  return `${walletAddressNetwork?.addressScannerUrl}${walletAddress}`;
};

export const getSVGStringForTicker = (assets: Asset[], assetTicker: string) => {
  return assets.find((asset) => asset.ticker === assetTicker)?.iconSVG || "";
};

export const getFeeWalletForWithdraw = (
  walletTransactionItem: WalletTransaction,
  allOrgWallets: Wallet[],
  assets: Asset[],
  coldTrustFeeWallets: Wallet[]
) => {
  const sourceWallet = allOrgWallets.find((wallet) => wallet.walletId === walletTransactionItem.sourceId);
  let feeWallet;
  if (sourceWallet?.type === WalletType.Custody) {
    feeWallet = allOrgWallets.find((wallet) => wallet.walletId === sourceWallet.feeWalletId);
  } else {
    const feeAssetSymbol = assets.find((asset) => asset.symbol === walletTransactionItem?.assetSymbol)?.feeAsset;
    feeWallet = coldTrustFeeWallets.find((wallet) => wallet.assetSymbol === feeAssetSymbol);
  }
  return feeWallet;
};

export const formatCurrentDate = () => {
  const date = new Date();

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const weekdayIndex = date.getDay();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const getOrdinalSuffix = (day: number): string => {
    if (day >= 11 && day <= 13) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  const dayName = weekdays[weekdayIndex];
  const monthName = months[monthIndex];
  const dayWithSuffix = day + getOrdinalSuffix(day);

  return `${dayName}, ${monthName} ${dayWithSuffix}`;
};
