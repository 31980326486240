import { AlertColor } from "@mui/material";

export type AlertDisplay = {
  severity: AlertColor;
  messageHeader?: string;
  message?: string;
};
export interface ActionResponse {
  success: boolean;
  title?: string;
  message?: string;
  data?: string;
}

export enum EnvType {
  Mainnet = "mainnet",
  Testnet = "testnet",
}

export enum collateralActionType {
  add = "ADD_COLLATERAL_WITHDRAW",
  remove = "ADD_COLLATERAL_DEPOSIT",
}
