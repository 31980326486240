import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  DataGridPremium,
  formatAmount,
  formatDollarAmountUsd,
  formatHash,
  formatWalletAddress,
  Icons,
  Typography,
} from "@bakkt/bakkt-ui-components";
import { Box, IconButton, Snackbar, SvgIcon, Tooltip, Unstable_Grid2 as Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";

import { AssetSVGIcon } from "../../components/CustomSVG/AssetSVGIcon.tsx";
import QuantityDisplay from "../../components/quantityDisplay/QuantityDisplay.tsx";
import { useRootContext } from "../../RootLayout";
import { Organization, Wallet } from "../../services/openAPI/internal";
import {
  convertPricingInfoArrayIntoMap,
  deriveWalletDetailsFromWallets,
  deriveWalletSummariesFromWallets,
  getSVGStringForTicker,
  WalletSummary,
} from "../../utils/dataUtils.ts";
import { tableHeader } from "./styles";

export function WalletsGrid() {
  const { organizations, wallets } = useLoaderData() as {
    organizations: Organization[];
    wallets: Wallet[];
  };
  const { priceFeed, assets } = useRootContext();
  const [pricingInfoMap, setPricingInfo] = useState<any>();
  const [walletSummaries, setWalletSummaries] = useState<any>();
  const [walletDetails, setWalletDetails] = useState<any>();
  const [showCopyAddressConfirm, setShowCopyAddressConfirm] = useState(false);

  useEffect(() => {
    setPricingInfo(convertPricingInfoArrayIntoMap(priceFeed));
    setWalletSummaries(deriveWalletSummariesFromWallets(wallets || [], priceFeed));
    setWalletDetails(deriveWalletDetailsFromWallets(wallets || []));
  }, [wallets, priceFeed]);

  const copyAddress = (address: string) => {
    navigator.clipboard.writeText(address || "");
    setShowCopyAddressConfirm(true);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "WALLET NAME",
      width: 145,
    },
    {
      field: "organizationId",
      headerName: "ORGANIZATION",
      width: 150,
      editable: false,
      valueGetter: (params: any) => {
        const orgName = organizations?.find((o) => o.id == params.value)?.name;
        return orgName || "";
      },
    },
    {
      field: "walletTypeId",
      headerName: "TEMP",
      width: 90,
      editable: false,
      valueGetter: (params: any) => {
        return params.row.temperature;
      },
    },
    {
      field: "address",
      headerName: "WALLET ADDRESS",
      width: 200,
      editable: false,
      renderCell: (params) => {
        if (params.value) {
          return (
            <>
              <IconButton aria-label="copy" onClick={() => copyAddress(params.value)}>
                <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={{ width: 15, height: 15 }} />
              </IconButton>
              <Snackbar
                open={showCopyAddressConfirm}
                onClose={() => setShowCopyAddressConfirm(false)}
                autoHideDuration={2000}
                message="Copied hash to clipboard"
              />
              <Tooltip title={params.value}>
                <span>{formatWalletAddress(params.value)}</span>
              </Tooltip>
            </>
          );
        }
      },
    },
    {
      field: "walletId",
      headerName: "ID",
      width: 70,
      editable: false,
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      description: "",
      sortable: false,
      width: 288,
    },
    {
      field: "quantity",
      headerName: "QTY",
      description: "",
      sortable: false,
      headerAlign: "right",
      align: "right",
      width: 90,
      renderCell: (params: any) => {
        if (params.rowNode.type === "group") {
          return "";
        } else {
          return (
            <>
              <QuantityDisplay quantity={params.value} ticker={params.row.assetTicker} />
            </>
          );
        }
      },
    },
    {
      // field is required, this doesn't actually match any field in the response, but valueGetter will display what we want
      field: "usdBalance",
      headerName: "BALANCE",
      description: "",
      sortable: false,
      headerAlign: "right",
      align: "right",
      width: 192,
      valueGetter: (params: any) => {
        const priceInfo = pricingInfoMap[params.row.assetTicker];
        return params.row.quantity * (priceInfo.bidPrice || 0);
      },
      valueFormatter: (params: any) => {
        return formatDollarAmountUsd(params.value);
      },
    },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        id: false,
      },
    },
  };

  function addAccountButton(event: any) {
    event.stopPropagation();
    return;
  }

  //TODO: Refactor styles into modules.css files
  const cryptoIconStyle = {
    position: "absolute",
    marginRight: "8px",
    top: "28px",
  };
  const cryptoNameStyle = {
    marginLeft: "34px",
  };

  return (
    <>
      <Grid container sx={{ flexGrow: 1, marginBottom: "20px", paddingLeft: "32px" }}>
        <Grid xs={5}>
          <Typography variant="overline" data-testid="label" sx={[tableHeader, { marginLeft: "-32px" }]}>
            ASSET
          </Typography>
        </Grid>
        <Grid xs={7} sx={{ flexGrow: 1, paddingRight: "0" }}>
          <>
            <Grid container sx={{ flexGrow: 1 }}>
              <Grid xs={3} sx={{ textAlign: "right" }}>
                <Typography variant="overline" data-testid="label" sx={tableHeader}>
                  MARKET PRICE
                </Typography>
              </Grid>
              <Grid xs={3} sx={{ textAlign: "right" }}>
                <Typography variant="overline" data-testid="label" sx={tableHeader}>
                  QUANTITY
                </Typography>
              </Grid>
              <Grid xs={3} sx={{ textAlign: "right" }}>
                <Typography variant="overline" data-testid="label" sx={tableHeader}>
                  BALANCE
                </Typography>
              </Grid>
            </Grid>
          </>
        </Grid>
      </Grid>

      {walletSummaries &&
        walletSummaries.map((walletSummary: WalletSummary, index: number) => (
          <Box key={index + "-wallet"}>
            <Accordion>
              <AccordionSummary
                aria-controls="panel-content"
                id={walletSummary.assetSymbol}
                data-testid={walletSummary.assetSymbol}
                sx={{ pointerEvents: "none", paddingLeft: "0" }}
                expandIcon={<SvgIcon component={Icons.ChevronDownIcon} sx={{ pointerEvents: "auto" }} />}
              >
                <Grid container sx={{ flexGrow: 1, marginTop: "10px", marginBottom: "5px" }}>
                  <Grid xs={5}>
                    <SvgIcon
                      component={() =>
                        AssetSVGIcon({
                          svgString: getSVGStringForTicker(assets, walletSummary.assetSymbol),
                          title: walletSummary.assetSymbol,
                          sx: cryptoIconStyle,
                        })
                      }
                      inheritViewBox
                    />
                    <Typography sx={{ mb: "-8px" }}>
                      <span style={cryptoNameStyle}>{walletSummary.asset}</span>
                    </Typography>
                    <Typography variant="overline" sx={cryptoNameStyle}>
                      {walletSummary.assetSymbol}
                    </Typography>
                  </Grid>
                  <Grid xs={7}>
                    <Box>
                      <Grid container sx={{ flexGrow: 1 }}>
                        <Grid xs={3} sx={{ textAlign: "right" }}>
                          <Typography sx={{ mb: "-3px" }}>
                            {formatDollarAmountUsd(walletSummary.marketPrice)}
                          </Typography>
                          {/*TODO: Coming back in Phase 2*/}
                          {/*<Typography variant="body2" sx={{ color: "green" }}>*/}
                          {/*  {walletSummary.percentChange} Today*/}
                          {/*</Typography>*/}
                        </Grid>
                        <Grid xs={3} sx={{ textAlign: "right" }}>
                          <Typography sx={{ pointerEvents: "auto" }}>
                            <QuantityDisplay quantity={walletSummary.quantity} ticker={walletSummary.assetSymbol} />
                          </Typography>
                        </Grid>
                        <Grid xs={3} sx={{ textAlign: "right" }}>
                          <Typography>{formatDollarAmountUsd(walletSummary.balance)}</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails data-testid="walletDetails">
                {walletSummary.assetSymbol && (
                  <DataGridPremium
                    autoHeight
                    {...{ columns, initialState, rows: walletDetails[walletSummary.assetSymbol] }}
                    disableRowSelectionOnClick
                    initialState={initialState}
                    getRowId={(row) => row.walletId}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
    </>
  );
}
