import { Paper, Typography } from "@bakkt/bakkt-ui-components";
import { Alert, Container, Stack, Unstable_Grid2 as Grid } from "@mui/material";
import React from "react";
import { Await, Outlet, useLoaderData } from "react-router-dom";

import LoadingIndicator from "../../components/loading/LoadingIndicator";
import { useRootContext } from "../../RootLayout";
import { fetchMockDataPromiseWithDelay, walletTransactions } from "../../services/mockData";
import { WalletTransaction } from "../../services/openAPI/internal";
import { WalletTransactionService } from "../../services/serviceLoader";
import { shouldUseMockData } from "../../utils/dataUtils";
import { innerGridContainer, subText, wrapperContainer } from "../dashboard/styles";
import { AllTransactionsGrid } from "./AllTransactionsGrid";

const AllTransactions = () => {
  const { transactionsPromise } = useLoaderData() as {
    transactionsPromise: Promise<WalletTransaction[]>;
  };

  return (
    <>
      <Outlet context={useRootContext()} />
      <Container maxWidth="xl" disableGutters sx={wrapperContainer}>
        <Grid container spacing={2}>
          <Grid xs={9}>
            <Typography variant="h3">Transactions</Typography>
            <Typography variant="body1" sx={subText}>
              Last updated today at 12:00 AM
            </Typography>
          </Grid>
        </Grid>

        <Paper>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Container sx={innerGridContainer} data-testid="allTransactionsTable">
                <React.Suspense
                  fallback={
                    <Stack sx={{ mt: 12, mb: 7 }}>
                      <LoadingIndicator />
                    </Stack>
                  }
                >
                  <Await
                    resolve={transactionsPromise}
                    errorElement={<Alert severity="error">Error loading transaction data!</Alert>}
                  >
                    <AllTransactionsGrid />
                  </Await>
                </React.Suspense>
              </Container>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default AllTransactions;

export async function loader() {
  const transactionsPromise = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(walletTransactions, 3000)
    : WalletTransactionService.getWalletTransactions();

  return {
    transactionsPromise: transactionsPromise,
  };
}
