import { Box, Checkbox, Divider, FormControlLabel, Unstable_Grid2 as Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useLoaderData } from "react-router-dom";

import { Organization, OrganizationPermission } from "../../services/openAPI/internal";
import { mapOrgFormDataToOrganization } from "../../utils/dataUtils";
import { OrganizationFormData } from "../organizations/AddEditOrganization";
import { UserFormData, UserOrgPermission } from "./AddEditUsers";

export interface OrgPermissionsFormData {
  organizationId: number;
  permissions: string[];
}

interface UserOrgPermissionsSelectProps {
  setSelectedOrgPermissions: (orgPermissions: OrgPermissionsFormData[]) => void;
  currentUser: UserFormData;
  isUpdate: boolean;
  updatedOrgs: OrganizationFormData[];
}

export default function UserOrgPermissionsSelect(props: UserOrgPermissionsSelectProps) {
  let { organizations } = useLoaderData() as { organizations: Organization[] };

  if (props.isUpdate) {
    organizations = mapOrgFormDataToOrganization(props.updatedOrgs);
  }
  const permissions = Object.values(OrganizationPermission);

  const initialState = organizations.map((org: Organization) => {
    return {
      organizationId: org.id,
      permissions: getCurrentUserOrgPermissions(props.currentUser, org.id),
    };
  });

  const [orgPermissions, setOrgPermissions] = React.useState<OrgPermissionsFormData[]>(initialState);

  useEffect(() => {
    props.setSelectedOrgPermissions(orgPermissions);
  }, [orgPermissions]);

  const handleUpdatePermission = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, checked } = event.target;
    let orgPermissionToUpdate = [...orgPermissions[index].permissions];

    if (checked) {
      if (orgPermissionToUpdate.indexOf(name) === -1) {
        orgPermissionToUpdate.push(name);
      }
    } else {
      orgPermissionToUpdate = orgPermissionToUpdate.filter((permission) => permission !== name);
    }

    const updatedOrgPermission = {
      ...orgPermissions[index],
      permissions: [...orgPermissionToUpdate],
    };

    if (name === OrganizationPermission.View && !checked) {
      updatedOrgPermission.permissions = [];
    }

    setOrgPermissions((prevOrgPermissions) => {
      const updatedOrgPermissions = [...prevOrgPermissions];
      updatedOrgPermissions[index] = updatedOrgPermission;
      return updatedOrgPermissions;
    });
  };

  return (
    <React.Fragment>
      {organizations.map((organization: Organization, orgIndex: number) => {
        return (
          <Box key={"orgPermissionRow-" + orgIndex} sx={{ ml: 8 }}>
            <Grid container id={`org-${orgIndex}`}>
              {props.isUpdate ? (
                <Grid xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={OrganizationPermission.View}
                        onChange={(event) => handleUpdatePermission(event, orgIndex)}
                        checked={orgPermissions[orgIndex].permissions.includes(OrganizationPermission.View)}
                      />
                    }
                    label={organization.name}
                    sx={{ ml: 1 }}
                  />
                </Grid>
              ) : (
                <Grid xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={OrganizationPermission.View}
                        onChange={(event) => handleUpdatePermission(event, orgIndex)}
                      />
                    }
                    label={organization.name}
                    sx={{ ml: 1 }}
                  />
                </Grid>
              )}
              <Grid xs={10}>
                {permissions.map((permission: string, permIndex: number) => {
                  if (permission != OrganizationPermission.View) {
                    return (
                      <React.Fragment key={"permissionRow-" + permIndex}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={permission}
                              disabled={!orgPermissions[orgIndex]?.permissions.includes(OrganizationPermission.View)}
                              checked={orgPermissions[orgIndex].permissions.includes(permission)}
                              onChange={(event) => handleUpdatePermission(event, orgIndex)}
                            />
                          }
                          label={permission}
                          sx={{ ml: 1 }}
                        />
                      </React.Fragment>
                    );
                  }
                })}
              </Grid>
            </Grid>
            {orgIndex != organizations.length - 1 && <Divider />}
          </Box>
        );
      })}
    </React.Fragment>
  );
}

function getCurrentUserOrgPermissions(currentUser: UserFormData, orgId: number) {
  if (currentUser?.organizations?.find((p) => p.organizationId === orgId)?.permissions) {
    //const permsArray=currentUser?.organizations?.find((p) => p.organizationId === orgId)?.permissions||[];
    return currentUser?.organizations?.find((p) => p.organizationId === orgId)?.permissions || [];
  }
  return [];
}
