import {
  Button,
  calculateAndFormatPercentage,
  Divider,
  formatDollarAmountUsd,
  Typography,
} from "@bakkt/bakkt-ui-components";
import { Box, Container, LinearProgress, Unstable_Grid2 as Grid } from "@mui/material";
import { useState } from "react";

import { useRootContext } from "../../RootLayout";
import { fetchMockDataPromiseWithDelay, lastMonthFinancialReportAll } from "../../services/mockData";
import { Wallet } from "../../services/openAPI/internal";
import { ReportService } from "../../services/serviceLoader";
import {
  calculateBalanceTotalsFromWallets,
  generateAndDownloadZip,
  getQuarantineWalletBalance,
  shouldUseMockData,
} from "../../utils/dataUtils.ts";
import { gridDivider, innerGridContainer, tableHeader, totalBalanceValue, warmColdValues } from "./styles";

interface BalancesProps {
  wallets: Wallet[];
  quarantined: Wallet[];
}

const Balances = ({ wallets, quarantined }: BalancesProps) => {
  const { priceFeed } = useRootContext();

  const balanceData = calculateBalanceTotalsFromWallets(wallets, priceFeed);
  const quarantineBalanceData = getQuarantineWalletBalance(quarantined, priceFeed);
  const totalBalance = balanceData.total + quarantineBalanceData.quarantine;
  const [loading, setLoading] = useState(false);

  async function getMonthlyFinanceReport() {
    setLoading(true);
    const allClientsBalanceReportData: any = shouldUseMockData
      ? await fetchMockDataPromiseWithDelay(lastMonthFinancialReportAll, 300)
      : await ReportService.getLastMonthFinanceReportAllAccounts();
    generateAndDownloadZip(allClientsBalanceReportData);
    setLoading(false);
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        {loading ? (
          <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", pb: 4, pt: 4, pl: 2, pr: 2 }}>
            <LinearProgress sx={{ width: "100%" }} />
          </Box>
        ) : (
          <Grid container justifyContent={"right"} sx={{ mt: 2, mr: 2 }}>
            <Button onClick={getMonthlyFinanceReport} variant={"contained"}>
              Export Billing Report
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid xs={12} sx={{ pb: 0 }}>
        <Container data-testid="balanceContainer" sx={innerGridContainer}>
          <Typography variant="overline" data-testid="label" sx={tableHeader}>
            Balances
          </Typography>
          <Typography variant="h2" data-testid="amount" sx={totalBalanceValue}>
            {formatDollarAmountUsd(totalBalance)}
          </Typography>
        </Container>
        <Divider flexItem sx={gridDivider} />
      </Grid>
      <Grid xs={3}>
        <Container data-testid="warmContainer" sx={innerGridContainer}>
          <Typography variant="overline" data-testid="label" sx={tableHeader}>
            Warm
          </Typography>
          <Typography variant="h3" data-testid="amount" sx={warmColdValues}>
            {formatDollarAmountUsd(balanceData.warm)}
          </Typography>
          <Typography variant="subtitle1" data-testid="warm-percentage">
            {totalBalance > 0 ? calculateAndFormatPercentage(balanceData.warm, totalBalance, 2) : "0.00%"}
          </Typography>
        </Container>
      </Grid>
      <Divider orientation="vertical" flexItem sx={gridDivider} />
      <Grid xs={5}>
        <Container data-testid="coldContainer" sx={innerGridContainer}>
          <Typography variant="overline" data-testid="label" sx={tableHeader}>
            Cold
          </Typography>
          <Typography variant="h3" data-testid="amount" sx={warmColdValues}>
            {formatDollarAmountUsd(balanceData.cold)}
          </Typography>
          <Typography variant="subtitle1" data-testid="cold-percentage">
            {totalBalance > 0 ? calculateAndFormatPercentage(balanceData.cold, totalBalance, 2) : "0.00%"}
          </Typography>
        </Container>
      </Grid>
      <Divider orientation="vertical" flexItem sx={gridDivider} />
      <Grid>
        <Container data-testid="quarantineContainer" sx={innerGridContainer}>
          <Typography variant="overline" data-testid="label" sx={tableHeader}>
            Quarantine
          </Typography>
          <Typography variant="h3" data-testid="amount" sx={warmColdValues}>
            {formatDollarAmountUsd(quarantineBalanceData.quarantine)}
          </Typography>
          <Typography variant="subtitle1" data-testid="quarantine-percent">
            {totalBalance > 0
              ? calculateAndFormatPercentage(quarantineBalanceData.quarantine, totalBalance, 2)
              : "0.00%"}
          </Typography>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Balances;
