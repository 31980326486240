import { Icons } from "@bakkt/bakkt-ui-components";

export const iconsMap: { [key: string]: any } = {
  DBIT: Icons.DeribitIcon,
};

export const enum WalletTemp {
  Warm = "Warm",
  Cold = "Cold",
}

export const enum CryptoTickerEnum {
  BTC = "BTC",
  ETH = "ETH",
  DOGE = "DOGE",
  LTC = "LTC",
  USDC = "USDC",
  BCH = "BCH",
  ETC = "ETC",
  SHIB = "SHIB",
  CHL = "CHL",
}
