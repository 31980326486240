import { Breadcrumbs, Link, Typography } from "@bakkt/bakkt-ui-components";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink, useLoaderData } from "react-router-dom";

import { Account } from "../../services/openAPI/internal";
import { capitalizeFirst } from "../../utils/dataUtils.ts";

export const ClientBreadCrumbs = () => {
  const { account } = useLoaderData() as {
    account: Account;
  };
  const theme = useTheme();

  const NavLinkStyle = {
    color: theme.palette.primary.main,
    textDecoration: "none",
  };
  const changeTextDecorationUnderline = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.currentTarget.style.textDecoration = "underline";
  };

  const changeTextDecorationNone = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.currentTarget.style.textDecoration = "none";
  };
  return (
    <Breadcrumbs aria-label="breadcrumb" separator={">"}>
      <RouterLink
        to={`/clients`}
        style={NavLinkStyle}
        onMouseOver={changeTextDecorationUnderline}
        onMouseLeave={changeTextDecorationNone}
      >
        Clients
      </RouterLink>
      <Typography>{capitalizeFirst(account.name)}</Typography>
    </Breadcrumbs>
  );
};

export default ClientBreadCrumbs;
