import axios from "axios";

import { API_URL } from "../utils/dataUtils";

export const redirectToLogin = () => {
  const LOGIN_URL = `${API_URL}/custody/opslogin`;
  window.location.assign(LOGIN_URL);
};

const globalSuccessHandler = (response: any) => {
  if (response.headers["content-type"]?.includes("application/json")) {
    return response.data;
  } else {
    return response;
  }
};
const globalErrorHandler = (error: any) => {
  if (error.response && error.response.status === 401) {
    redirectToLogin();
  }

  return Promise.reject({ ...error });
};

export function registerAxiosConfig() {
  axios.interceptors.response.use(globalSuccessHandler, globalErrorHandler);
  axios.defaults.withCredentials = true;
}
