import { color } from "@bakkt/bakkt-ui-components";

export const wrapperContainer = {
  mb: "68px",
  mt: "50px",
};

export const subText = {
  mt: 0.625,
  mb: 2.5,
};

export const innerGridContainer = {
  pr: 3,
  pl: 3,
  pb: 3,
  pt: 1,
  border: "none",
};

export const tableHeader = {
  color: color.techBlack["700"],
  fontSize: "16px",
  lineHeight: "20px",
};

export const totalBalanceValue = {
  fontWeight: 700,
  fontSize: "40px",
  lineHeight: "51px",
  marginBottom: "8px",
};

export const warmColdValues = {
  lineHeight: "51px",
  marginBottom: "8px",
};

export const gridDivider = {
  mr: "-1px",
};
