import { Alert, Paper, Typography } from "@bakkt/bakkt-ui-components";
import { Container, Stack, Unstable_Grid2 as Grid } from "@mui/material";
import React from "react";
import { Await, defer, LoaderFunctionArgs, Outlet, useLoaderData } from "react-router-dom";

import Alerts from "../../components/alerts/Alerts";
import LoadingIndicator from "../../components/loading/LoadingIndicator";
import { useRootContext } from "../../RootLayout";
import { allow, fetchMockDataPromiseWithDelay, organizations } from "../../services/mockData";
import { AllowlistAddress, Organization } from "../../services/openAPI/internal";
import { AllowlistService, OrganizationService } from "../../services/serviceLoader";
import { shouldUseMockData } from "../../utils/dataUtils";
import { subText, wrapperContainer } from "../dashboard/styles";
import { AllowlistGrid } from "./AllowListItems";

const Allowlist = () => {
  const { orgsList, allowlistItemsPromise } = useLoaderData() as {
    orgsList: Organization[];
    allowlistItemsPromise: AllowlistAddress[];
  };
  return (
    <>
      <Outlet context={useRootContext()} />
      <Alerts />
      <Container maxWidth="xl" disableGutters sx={wrapperContainer}>
        <Grid container spacing={2}>
          <Grid xs={9}>
            <Typography variant="h3">Allowlist</Typography>
            <Typography variant="body1" sx={subText}>
              Manage the addresses you can use for asset transfers.
            </Typography>
          </Grid>
        </Grid>

        <Paper>
          <Grid container spacing={2} sx={{ pt: 2.5 }}>
            <Grid xs={12}>
              <Container data-testid="allowlistTable">
                <React.Suspense
                  fallback={
                    <Stack sx={{ mt: 12, mb: 7 }}>
                      <LoadingIndicator />
                    </Stack>
                  }
                >
                  <Await
                    resolve={allowlistItemsPromise}
                    errorElement={<Alert severity="error">Error loading allowlist data!</Alert>}
                  >
                    <AllowlistGrid />
                  </Await>
                </React.Suspense>
              </Container>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default Allowlist;

export async function loader({ params }: LoaderFunctionArgs) {
  try {
    const orgsPromise = shouldUseMockData
      ? fetchMockDataPromiseWithDelay(organizations, 200)
      : OrganizationService.getOrganizations();

    const allowPromise = shouldUseMockData
      ? fetchMockDataPromiseWithDelay(allow, 200)
      : AllowlistService.getAllowlistAddresses();

    return defer({
      orgsList: await orgsPromise,
      allowlistItemsPromise: allowPromise,
    });
  } catch (e) {
    console.log("Error fetching allowlist data: ", e);
    return {};
  }
}
