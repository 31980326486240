import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useRootContext } from "../RootLayout";
import {
  allow,
  fetchMockDataPromiseWithDelay,
  organizations,
  users,
  wallets,
  walletTransactions,
} from "../services/mockData";
import {
  AllowlistAddress,
  EstimateFee,
  Organization,
  OrganizationPermission,
  PolicyActionDetails,
  User,
  Wallet,
  WalletTransaction,
  WalletTransactionTypeEnum,
} from "../services/openAPI/internal";
import {
  AllowlistService,
  OrganizationService,
  UserService,
  WalletService,
  WalletTransactionService,
} from "../services/serviceLoader";
import { getFeeWalletForWithdraw, getPriceByAssetQty, isAssetToken, shouldUseMockData } from "../utils/dataUtils";

export interface ReviewTransactionMinimal {
  assetTicker?: string;
  assetSymbol: string;
  transactionId?: string;
  walletTransactionId?: number;
  fromWalletId?: number;
  fromWalletAddress?: string;
  toWalletAddress?: string;
  destinationId?: number;
  destinationName?: string;
  destinationType?: string;
  quantity?: string;
  originatorId?: number;
  createdOn?: string;
  accountId?: number;
  organizationId?: number;
  accountName?: string;
  organizationName?: string;
  originatorName?: string;
  isAssetToken?: boolean;
  gasBalance?: number;
  feeWallet?: Wallet;
  transactionType?: string;
}

const useReviewTransaction = (reviewItem: PolicyActionDetails) => {
  const { priceFeed, assets, coldTrustFeeWallets } = useRootContext();
  const routeParams = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reviewTransaction, setReviewTransaction] = useState<ReviewTransactionMinimal>({} as ReviewTransactionMinimal);
  const [wallet, setWallet] = useState<Wallet | undefined>(undefined);
  const [allowlistAddresses, setAllowlistAddresses] = useState<AllowlistAddress[]>([]);
  const [walletTransactionItems, setWalletTransactionItems] = useState<WalletTransaction[]>([]);
  const [videoAuthOrgUsers, setVideoAuthOrgUsers] = useState<User[]>([]);
  const [videoAuthIsRequired, setVideoAuthIsRequired] = useState<boolean>(false);
  const [videoAuthAffirmed, setVideoAuthAffirmed] = useState<boolean>(true);
  const [networkFeeEstimation, setNetworkFeeEstimation] = useState<EstimateFee>({});

  const orgId = Number(JSON.parse(String(reviewItem.policyInstancePayload)).organizationId);

  // get list of orgs and check if video auth is required
  const checkIfVideoAuthNeeded = (
    organizationDetails: Organization,
    reviewTransactionFormData: ReviewTransactionMinimal
  ): void => {
    if (organizationDetails) {
      //check if transaction is over the organization set threshold
      const assetPrice = getPriceByAssetQty(
        reviewTransactionFormData.assetTicker as string,
        Number(reviewTransactionFormData.quantity),
        priceFeed
      );
      const threshold = organizationDetails!.withdrawalAuthorization.amountLimit || 0;
      if (assetPrice > threshold) {
        setVideoAuthIsRequired(organizationDetails!.withdrawalAuthorization.videoAuthorization);
        if (organizationDetails!.withdrawalAuthorization.videoAuthorization == true) {
          setVideoAuthAffirmed(false);
        }
      }
    }
  };

  const getTransactionDetails = async () => {
    // kick off org and users loading
    const organizationDetails: Organization = shouldUseMockData
      ? ((await fetchMockDataPromiseWithDelay(organizations.find((o) => o.id == orgId) || [], 200)) as any)
      : ((await OrganizationService.getOrganization(orgId)) as any);

    const orgUsers: User[] = shouldUseMockData
      ? ((await fetchMockDataPromiseWithDelay(users, 200)) as any)
      : ((await UserService.getUsers(undefined, [orgId])) as any);
    const videoAuthOrgUsers: User[] = orgUsers.filter((u) => {
      const org = u.organizations!.find((o) => o.organizationId == orgId);
      if (org && org.activePermissions!.includes(OrganizationPermission.VideoAuthorization)) {
        return u;
      }
    });
    setVideoAuthOrgUsers(videoAuthOrgUsers);

    // get allowlists by org
    const allowListedAddresses: AllowlistAddress[] = shouldUseMockData
      ? ((await fetchMockDataPromiseWithDelay(allow, 200)) as any)
      : ((await AllowlistService.getAllowlistAddresses(orgId)) as any);
    setAllowlistAddresses(allowListedAddresses);

    // get all transactions by orgId
    const orgTransactions: WalletTransaction[] = shouldUseMockData
      ? ((await fetchMockDataPromiseWithDelay(walletTransactions, 300)) as any)
      : ((await WalletTransactionService.getWalletTransactions(undefined, orgId)) as any);

    // find transaction item
    let walletTransactionItem = orgTransactions.find(
      (w) => w.walletTransactionId == routeParams.walletTransactionId
    ) as WalletTransaction;

    // find the matching correlationId Trading Wallet Transaction
    const walletTransactionTradingItem = orgTransactions.find(
      (w) =>
        w.walletTransactionId !== walletTransactionItem.walletTransactionId &&
        w.correlationId == walletTransactionItem.correlationId &&
        w.destinationAddress?.startsWith("bkkt")
    ) as WalletTransaction;

    // if(walletTransactionTradingItem)
    if (walletTransactionTradingItem) {
      walletTransactionItem = walletTransactionTradingItem;
    }

    // find walletId
    const walletId =
      walletTransactionItem.type === WalletTransactionTypeEnum.Deposit
        ? Number(walletTransactionItem.destinationId)
        : Number(walletTransactionItem.sourceId);

    // get wallet by walletId
    const wallet: Wallet = shouldUseMockData
      ? ((await fetchMockDataPromiseWithDelay(wallets[0], 300)) as any)
      : ((await WalletService.getWalletById(walletId)) as any);
    setWallet(wallet);

    // filter transactions by walletId for "available" math
    const transactionItems = orgTransactions.filter(
      (t: WalletTransaction) => t.sourceId == walletId
    ) as WalletTransaction[];
    setWalletTransactionItems(transactionItems);

    // get allowListItem
    const allowlistItem = allowListedAddresses.find((w) => w.id == walletTransactionItem?.destinationId);

    // find destinationAddress
    const destinationAddress =
      walletTransactionItem.type == WalletTransactionTypeEnum.Withdraw
        ? allowlistItem?.address
        : walletTransactionItem.destinationAddress;

    //For ERC-20 Assets calculate GAS balance, for now default ETH Warm wallet will serve as Fee Wallet.
    const isToken = isAssetToken(walletTransactionItem.assetSymbol || "", assets);
    const allOrgWallets: Wallet[] = shouldUseMockData
      ? ((await fetchMockDataPromiseWithDelay(wallets[0], 300)) as any)
      : ((await WalletService.getWallets(undefined, orgId)) as any);
    const txWallet: Wallet | null =
      allOrgWallets.find((wallet) =>
        reviewItem.policyInstanceRequestType === WalletTransactionTypeEnum.Withdraw
          ? wallet.walletId === walletTransactionItem.sourceId
          : wallet.walletId === walletTransactionItem.destinationId
      ) || null;

    // Get network fee estimation for withdraws
    if (reviewItem.policyInstanceRequestType === WalletTransactionTypeEnum.Withdraw) {
      const feeEstimation = await WalletTransactionService.getEstimateFee(
        Number(walletTransactionItem.quantity),
        Number(walletTransactionItem.sourceId),
        String(walletTransactionItem.destinationAddress)
      );

      setNetworkFeeEstimation(feeEstimation as EstimateFee);
    }

    // set TransactionsDetails
    const reviewTransactionFormData: ReviewTransactionMinimal = {
      assetTicker: walletTransactionItem?.assetTicker,
      assetSymbol: walletTransactionItem?.assetSymbol || "",
      transactionId: walletTransactionItem?.blockchainTransactionId,
      walletTransactionId: walletTransactionItem?.walletTransactionId,
      fromWalletId: walletTransactionItem?.sourceId,
      fromWalletAddress: walletTransactionItem?.sourceName,
      toWalletAddress: destinationAddress, //TODO: have backend provide "to" address on walletTransaction response
      destinationId: walletTransactionItem?.destinationId,
      destinationName: walletTransactionItem?.destinationName,
      destinationType: walletTransactionItem?.destinationType,
      quantity: walletTransactionItem?.quantity?.toString(),
      originatorName: reviewItem?.requester,
      accountName: reviewItem?.client,
      organizationName: organizationDetails?.name,
      createdOn: reviewItem?.timestamp?.toString(),
      transactionType: walletTransactionItem?.type,
    };
    setReviewTransaction(reviewTransactionFormData);
    checkIfVideoAuthNeeded(organizationDetails, reviewTransactionFormData);
    setIsLoading(false);
    const feeWallet =
      walletTransactionItem.type === WalletTransactionTypeEnum.Withdraw &&
      isAssetToken(walletTransactionItem.assetSymbol || "", assets)
        ? getFeeWalletForWithdraw(walletTransactionItem, allOrgWallets, assets, coldTrustFeeWallets)
        : undefined;
    //If there is no feeWallet set gasBalance to 0.
    const gasBalance = feeWallet ? feeWallet.availableBalance : 0;
    addERC20Values();

    function addERC20Values() {
      const isToken = isAssetToken(walletTransactionItem.assetSymbol || "", assets);
      if (isToken) {
        reviewTransactionFormData.gasBalance = gasBalance;
        if (feeWallet) {
          reviewTransactionFormData.feeWallet = feeWallet;
        }
      }

      reviewTransactionFormData.isAssetToken = isToken;
    }
  };

  useEffect(() => {
    getTransactionDetails();
  }, []);

  return {
    isLoading,
    reviewTransaction,
    wallet,
    allowlistAddresses,
    walletTransactionItems,
    videoAuthOrgUsers,
    videoAuthIsRequired,
    videoAuthAffirmed,
    setVideoAuthAffirmed,
    networkFeeEstimation,
  };
};

export default useReviewTransaction;
