import { color, Icons, UserInfo } from "@bakkt/bakkt-ui-components";
import {
  Button,
  Container,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  SvgIcon,
  TextField,
  Toolbar,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Await, Link, useLocation, useNavigate, useParams, useRouteLoaderData } from "react-router-dom";

import { Account, Organization } from "../../services/openAPI/internal";
import { isDa } from "../../utils/permissionsUtil";
import LoadingIndicator from "../loading/LoadingIndicator";

export const BakktToolbar = () => {
  const [selectedAccountId, setSelectedAccountId] = useState<string>("");
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string>("");
  const [accountOrgs, setAccountOrgs] = useState<Organization[] | null>(null);
  const navigate = useNavigate();

  const {
    userInfo,
    accounts,
    orgs: organizations,
  } = useRouteLoaderData("root") as {
    userInfo: UserInfo;
    accounts: Account[];
    orgs: Organization[];
  };
  const { accountId, organizationId } = useParams();
  const handleAccountChange = (event: SelectChangeEvent) => {
    setSelectedAccountId(event.target.value);
    setSelectedOrganizationId("");
  };

  const handleOrganizationChange = (event: SelectChangeEvent) => {
    setSelectedOrganizationId(event.target.value);
  };

  const contrastText = {
    color: "primary.contrastText",
  };

  const linkTextSx = {
    color: "primary.contrastText",
  };
  const inputLabelText = {
    color: "primary.contrastText",
    "&.Mui-focused": { color: "primary.contrastText" },
  };

  const borderBottom = {
    borderBottom: `1px solid`,
    borderColor: `primary.contrastText`,
  };

  const runButton = {
    border: `1px solid`,
    borderColor: `primary.contrastText`,
    borderRadius: `5px`,
    mt: `10px`,
    fontWeight: `bold`,
  };
  const isAuditLog = useLocation()
    .pathname.split("/")
    .filter((path) => path !== "")
    .includes("audit");

  const handleRun = () => {
    navigate(`/audit/${selectedAccountId}/${selectedOrganizationId}`);
  };
  const shouldShowButton = selectedAccountId !== "" && selectedOrganizationId !== "";

  return (
    <Container disableGutters maxWidth="xl">
      <Toolbar>
        <Grid container xs={12} justifyContent="space-between" alignItems="center" sx={{ mt: 2.5 }}>
          {/*The following Grid container is added so that we will always have a placeholder to preserve layout */}
          <Grid container xs={9} alignItems="center">
            {isAuditLog && (
              <Grid container xs={12} spacing={5} justifyContent={"space-between"} alignItems="center">
                <Grid xs={5}>
                  <FormControl variant="standard" fullWidth sx={borderBottom}>
                    <React.Suspense fallback={<LoadingIndicator />}>
                      <Await resolve={accounts} errorElement={<Typography>Error loading accounts!</Typography>}>
                        {(accounts: Account[]) => (
                          <>
                            <InputLabel sx={inputLabelText}>Client</InputLabel>
                            <Select
                              label="Client"
                              value={selectedAccountId}
                              sx={contrastText}
                              onChange={handleAccountChange}
                              input={<Input />}
                            >
                              {accounts
                                .sort((account1: Account, account2: Account) =>
                                  account1.name > account2.name ? 1 : -1
                                )
                                .map((account: Account) => (
                                  <MenuItem key={account.id} value={account.id.toString()}>
                                    {account.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </>
                        )}
                      </Await>
                    </React.Suspense>
                  </FormControl>
                </Grid>
                <Grid xs={5}>
                  <FormControl variant="standard" fullWidth sx={borderBottom}>
                    <React.Suspense fallback={<LoadingIndicator />}>
                      <Await
                        resolve={organizations}
                        errorElement={<Typography>Error loading organizations!</Typography>}
                      >
                        {(organizations: Organization[]) => (
                          <>
                            <InputLabel sx={inputLabelText}>Organization</InputLabel>
                            <Select
                              label="Organization"
                              value={selectedOrganizationId}
                              sx={contrastText}
                              onChange={handleOrganizationChange}
                              input={<Input />}
                            >
                              {organizations
                                .filter((org) => String(org.accountId) === selectedAccountId)
                                .sort((org1: Organization, org2: Organization) => (org1.name > org2.name ? 1 : -1))
                                .map((org: Organization) => (
                                  <MenuItem key={org.id} value={org.id.toString()}>
                                    {org.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </>
                        )}
                      </Await>
                    </React.Suspense>
                  </FormControl>
                </Grid>
                <Grid container xs={2} alignItems="center">
                  {shouldShowButton && (
                    <Grid xs={2}>
                      <Button onClick={handleRun} sx={{ ...contrastText, ...runButton }}>
                        RUN
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container xs={3} justifyContent={"flex-end"} alignItems="center">
            <Grid>
              <Stack direction="row">
                {isDa(userInfo) && (
                  <Link to={"/clients/new"}>
                    <Button variant={"contained"}>
                      <SvgIcon component={Icons.PlusIcon} inheritViewBox sx={{ width: 20, height: 20, pr: 1 }} />
                      New Client
                    </Button>
                  </Link>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </Container>
  );
};
