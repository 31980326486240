import { UserInfo } from "@bakkt/bakkt-ui-components";

import { fetchMockDataPromiseWithDelay } from "../services/mockData.ts";
import { FusionAuthService } from "../services/serviceLoader.ts";
import { API_URL, shouldUseMockData } from "./dataUtils.ts";

//TODO: Logout will not work until we are given a functioning logout URL from Okta
export async function handleLogout() {
  const userInfoSession: UserInfo = JSON.parse(sessionStorage.getItem("USERINFO") || "{}");
  const logoutResponse = shouldUseMockData
    ? await fetchMockDataPromiseWithDelay({ 200: { message: "success" } }, 1000)
    : await FusionAuthService.fusionAuthUserLogout(userInfoSession.userId);

  const LOGIN_URL = `${API_URL}/custody/opslogin`;
  window.location.replace(LOGIN_URL);
}
