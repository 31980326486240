import { UserFormData, UserOrgPermission } from "../../pages/users/AddEditUsers";
import { Organization, OrganizationPermission } from "../../services/openAPI/internal";

export interface OrgUserValidation {
  title: string;
  message: string;
}

export const validateAllOrgs = (orgs: Organization[], accountUsers: UserFormData[]): OrgUserValidation => {
  return (
    orgs
      .map((o) => {
        return validateOrg(o, accountUsers);
      })
      .find((validation) => validation.title && validation.message) || { title: "", message: "" }
  );
};
export const validateOrg = (org: Organization, accountUsers: UserFormData[]): OrgUserValidation => {
  const orgUserValidation: OrgUserValidation = { title: "", message: "" };
  const orgUsers = getOrgUsers(org.id, accountUsers);

  const numOfInitiators =
    orgUsers.filter((user) =>
      user.organizations
        ?.find((o) => o.organizationId === org.id)
        ?.permissions?.includes(OrganizationPermission.Initiator)
    ).length || 0;
  const numOfApprovers: number =
    orgUsers.filter((user) =>
      user.organizations
        ?.find((o) => o.organizationId === org.id)
        ?.permissions?.includes(OrganizationPermission.Approver)
    ).length || 0;
  const numOfVideoAuth: number =
    orgUsers.filter((user) =>
      user.organizations
        ?.find((o) => o.organizationId === org.id)
        ?.permissions?.includes(OrganizationPermission.VideoAuthorization)
    ).length || 0;
  const numOfTeamManagers = accountUsers.filter((user) => user.isTeamManager).length || 0;

  if (numOfApprovers < 1 || numOfInitiators < 1 || numOfTeamManagers < 1) {
    orgUserValidation.title = orgUserValidation.title
      ? orgUserValidation.title
      : "Organization " + org.name + ": validation error(s).";
    orgUserValidation.message =
      orgUserValidation.message +
      "At least one Initiator and one Approver per Organization and one Team Manager per account is required.\n";
  }
  if (org.numberOfConsensus > numOfApprovers) {
    orgUserValidation.title = orgUserValidation.title
      ? orgUserValidation.title
      : "Organization " + org.name + ": validation error(s).";
    orgUserValidation.message =
      orgUserValidation.message +
      "Number of consensus/approvers should be greater than or equal to " +
      org.numberOfConsensus +
      ". Currently, there are " +
      numOfApprovers +
      " Approvers.\n";
  }
  if (org.withdrawalAuthorization.videoAuthorization && numOfVideoAuth < 1) {
    orgUserValidation.title = orgUserValidation.title
      ? orgUserValidation.title
      : "Organization " + org.name + ": validation error(s).";
    orgUserValidation.message =
      orgUserValidation.message +
      "At least one Video Authorization user is required as video authorization is enabled for this organization.";
  }
  return orgUserValidation;
};

function getOrgUsers(orgId: number, accountUsers: UserFormData[]) {
  return accountUsers.filter((user) => isOrgFound(orgId, user.organizations));
}

function isOrgFound(orgId: number, orgLevelPerms: UserOrgPermission[] | null | undefined): boolean {
  const result = orgLevelPerms?.some((perm) => perm.organizationId === orgId);
  return result || false;
}
