export const subText = {
  mt: 0.625,
  mb: 2.5,
  fontWeight: 500,
  size: "20px",
};

export const innerGridContainer = {
  p: "20px",
};

export const wrapperContainer = {
  mb: "68px",
  mt: "50px",
};
