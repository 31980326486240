import { UserInfo } from "@bakkt/bakkt-ui-components";

import { PolicyStatusEnum } from "../services/openAPI/internal";

export function isDa(userInfo: UserInfo): boolean {
  const DA = JSON.parse(userInfo.roles)?.find((x: any) => x == "DA");
  return !!DA;
}

export function isPolicyDisabled(
  instanceStatus: PolicyStatusEnum,
  sequenceStatus: PolicyStatusEnum,
  userInfo: UserInfo,
  isApproved: boolean
): boolean {
  if (
    (sequenceStatus === PolicyStatusEnum.Completed && instanceStatus === PolicyStatusEnum.Completed) ||
    (sequenceStatus === PolicyStatusEnum.Cancelled && instanceStatus === PolicyStatusEnum.Cancelled) ||
    sequenceStatus === PolicyStatusEnum.Expired ||
    instanceStatus === PolicyStatusEnum.Expired ||
    !isDa(userInfo) ||
    isApproved
  ) {
    return true;
  }
  return false;
}
