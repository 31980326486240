import {
  Alert,
  Button,
  color,
  DataGridPremium,
  formatCurrency,
  formatUnixTime,
  Icons,
  Link,
  Paper,
  Tab,
  UserInfo,
} from "@bakkt/bakkt-ui-components";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { Box, Chip, Container, Stack, SvgIcon, Tabs, Unstable_Grid2 as Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { GridColDef, GridToolbarContainer } from "@mui/x-data-grid-premium";
import { GridRowParams } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { Await, Link as RouterLink, useLoaderData } from "react-router-dom";

import LoadingIndicator from "../../components/loading/LoadingIndicator";
import { useRootContext } from "../../RootLayout.tsx";
import { Account, AccountPermission, Organization, Status, User } from "../../services/openAPI/internal";
import { getActiveUsersByOrgId, StatusTabOption } from "../../utils/dataUtils.ts";
import { isDa } from "../../utils/permissionsUtil.ts";
import { errorIcon, internalGrid, wrapperContainer } from "./styles";

const tabs = [
  { value: StatusTabOption.Active, label: "Active" },
  { value: StatusTabOption.Inactive, label: "Inactive" },
];

const activeOrgStatus = Object.values(Status).filter((statusValue) => statusValue !== Status.Deleted);

const inactiveOrgStatus = Object.values(Status).filter((statusValue) => statusValue === Status.Deleted);

//This will be changed to enum once "PENDING-APPROVAL", "PENDING-ACTIVATION" are added to status enum.
const activeUserStatus = Object.values(Status).filter(
  (statusValue) => statusValue !== Status.Deleted && statusValue !== Status.Locked
);

export const DetailPanelContent = ({ row: org }: any) => {
  function getActiveOrgPermissions(user: User, orgId: number) {
    const orgPerms =
      (user.organizations
        ?.find((orgPerm) => orgPerm.organizationId === orgId)
        ?.activePermissions?.map((p) => p.replace("_", " ")) as string[]) || [];
    const accountPerms =
      (user.accountPermissions?.activePermissions
        ?.filter((accountPerm) => accountPerm === AccountPermission.TeamManager)
        ?.map((p) => p.replace("_", " ")) as string[]) || [];
    return [...accountPerms, ...orgPerms];
  }

  function renderPermissions(permissions: string[] | undefined) {
    return permissions && permissions?.length > 0 ? (
      <Stack direction={"row"} spacing={1}>
        {permissions?.map((permission, index) => (
          <Chip key={index} label={permission} />
        ))}
      </Stack>
    ) : (
      <Chip key={0 + "-view-only"} label={"VIEW-ONLY"} />
    );
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 20,
      flex: 1,
    },
    {
      field: "users",
      headerName: "USERS",
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row }) => {
        return row.name.firstName + " " + row.name.lastName;
      },
    },
    {
      field: "permissions",
      headerName: "PERMISSIONS",
      width: 1050,
      renderCell: ({ row }) => renderPermissions(getActiveOrgPermissions(row, org.id)),
    },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        id: false,
      },
    },
  };

  const data = { columns, initialState, rows: org.orgUsers };

  return (
    <Paper>
      <DataGridPremium
        sx={internalGrid}
        {...data}
        initialState={{
          ...data.initialState,
        }}
        hideFooter
      />
    </Paper>
  );
};

export const ClientOrgs = () => {
  const theme = useTheme();
  const { userInfo } = useRootContext() as {
    userInfo: UserInfo;
  };

  const NavLinkStyle = {
    color: theme.palette.primary.main,
    textDecoration: "none",
  };
  const changeTextDecorationUnderline = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.currentTarget.style.textDecoration = "underline";
  };

  const changeTextDecorationNone = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.currentTarget.style.textDecoration = "none";
  };

  const { account, organizations, users } = useLoaderData() as {
    account: Account;
    organizations: Organization[];
    users: User[];
  };
  // For each org, fetch a list of users that are associated with that Org and add the list as new prop called 'orgUsers'
  // orgUsers will be used by the DetailPanelContent to show the list of users and their permissions.
  const orgsWithUsers = organizations.map((org) => ({
    ...org,
    orgUsers: getActiveUsersByOrgId(users, org.id, activeUserStatus),
  }));

  const [filteredOrgs, setFilteredOrgs] = useState(orgsWithUsers.filter((org) => activeOrgStatus.includes(org.status)));

  const filterActiveInactiveOrgs = (currTab: string) => {
    if (currTab === StatusTabOption.Active) {
      setFilteredOrgs(orgsWithUsers.filter((org) => activeOrgStatus.includes(org.status)));
    } else if (currTab === StatusTabOption.Inactive) {
      setFilteredOrgs(orgsWithUsers.filter((org) => inactiveOrgStatus.includes(org.status)));
    } else {
      setFilteredOrgs(orgsWithUsers);
    }
  };
  const [selectedTab, setSelectedTab] = useState(StatusTabOption.Active);

  const handleTabChange = (event: React.SyntheticEvent, value: any) => {
    setSelectedTab(value);
    filterActiveInactiveOrgs(value);
    console.log(event);
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Grid container xs={12} justifyContent={"space-between"}>
          <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor={"secondary"}>
            {tabs.map((tab) => (
              <Tab key={tab.value} value={tab.value} label={tab.label}></Tab>
            ))}
          </Tabs>
          {isDa(userInfo) && (
            <RouterLink to={`/clients/${account.id}/organizations/edit`}>
              <Button variant={"contained"} sx={{ mt: 1, mb: 1 }}>
                <SvgIcon component={Icons.PlusIcon} inheritViewBox sx={{ width: 20, height: 20, pr: 1 }} />
                New Organization
              </Button>
            </RouterLink>
          )}
        </Grid>
      </GridToolbarContainer>
    );
  };

  const withdrawAuthGetter = (params: any) => {
    const amountLimit = params.row.withdrawalAuthorization.videoAuthorization
      ? params.row.withdrawalAuthorization.amountLimit
      : "";
    return amountLimit;
  };

  const formatWithdrawAuthValue = (params: any) => {
    if (isNaN(params.value) || params.value === "") {
      return (
        <Link href={"#"}>
          <Grid container direction={"row"} alignItems={"center"}>
            <ErrorRoundedIcon sx={errorIcon} />
            <Typography variant={"body2"} sx={{ color: color.engagingOrange.dark }}>
              Add Withdraw Video Auth
            </Typography>
          </Grid>
        </Link>
      );
    } else {
      return formatCurrency(params.value);
    }
  };

  const editDeactivateLinks = () => {
    return (
      <div>
        {selectedTab === StatusTabOption.Active ? (
          <>
            <RouterLink
              to={`/clients/${account.id}/organizations/edit`}
              style={NavLinkStyle}
              onMouseOver={changeTextDecorationUnderline}
              onMouseLeave={changeTextDecorationNone}
            >
              Edit
            </RouterLink>{" "}
            |{"   "}
            {/*//TODO: Will be implemented once design is provided.*/}
            <RouterLink
              to={"#"}
              style={NavLinkStyle}
              onMouseOver={changeTextDecorationUnderline}
              onMouseLeave={changeTextDecorationNone}
            >
              Deactivate
            </RouterLink>
          </>
        ) : (
          //TODO: Will be implemented once design is provided.
          <RouterLink
            to={`#`}
            style={NavLinkStyle}
            onMouseOver={changeTextDecorationUnderline}
            onMouseLeave={changeTextDecorationNone}
          >
            Activate
          </RouterLink>
        )}
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 40,
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
      flex: 1,
    },

    {
      field: "activatedOn",
      headerName: "ACTIVATION DATE",
      minWidth: 80,
      flex: 0.8,
      valueGetter: (params) => formatUnixTime(params.value),
    },
    {
      field: "location",
      headerName: "LOCATION",
      minWidth: 280,
      flex: 1,
      valueGetter: () => {
        return account.address.state + " , " + account.address.city + " , " + account.address.country;
      },
    },
    {
      field: "status",
      headerName: "STATUS",
      minWidth: 30,
      flex: 0.5,
    },
    {
      field: "numberOfConsensus",
      headerName: "NUMBER OF APPROVERS",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "withdrawVideoAuth",
      headerName: "WITHDRAW VIDEO AUTH",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => withdrawAuthGetter(params),
      renderCell: (params) => formatWithdrawAuthValue(params),
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      minWidth: 120,
      align: "right",
      headerAlign: "right",
      renderCell: () => (isDa(userInfo) ? editDeactivateLinks() : <></>),
    },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        id: false,
      },
    },
  };

  const data = { columns, initialState, rows: filteredOrgs };

  const getDetailPanelContent = React.useCallback(
    ({ row }: GridRowParams) =>
      row.orgUsers.length > 0 ? (
        <DetailPanelContent row={row} />
      ) : (
        <Paper>
          <Typography variant={"body1"} sx={{ textAlign: "center", pt: 3, pb: 3 }}>
            No Active Users found
          </Typography>
        </Paper>
      ),
    []
  );

  function customDetailPanelExpandIcon() {
    return <SvgIcon component={Icons.ChevronDownIcon} inheritViewBox sx={{ width: 20, height: 20 }} />;
  }

  function customDetailPanelCollapseIcon() {
    return <SvgIcon component={Icons.ChevronUpIcon} inheritViewBox sx={{ width: 20, height: 20 }} />;
  }

  return (
    <>
      <Container maxWidth="xl" disableGutters sx={wrapperContainer} data-testid="ParentOrganizationsContainer">
        <Grid container spacing={2}>
          <Grid xs={9}>
            <Typography variant="h3" sx={{ mb: 2.5 }}>
              Organizations
            </Typography>
          </Grid>
        </Grid>
        <Paper>
          <React.Suspense fallback={<LoadingIndicator />}>
            <Await
              resolve={organizations}
              errorElement={<Alert severity={"error"}>Error loading organization data!</Alert>}
            >
              <Box sx={{ height: "100%", flex: 1, width: "100%" }}>
                <DataGridPremium
                  sx={internalGrid}
                  slots={{
                    toolbar: CustomToolbar,
                    detailPanelExpandIcon: customDetailPanelExpandIcon,
                    detailPanelCollapseIcon: customDetailPanelCollapseIcon,
                  }}
                  {...data}
                  initialState={{
                    ...data.initialState,
                  }}
                  getDetailPanelContent={getDetailPanelContent}
                  getDetailPanelHeight={() => "auto"}
                  hideFooter
                  hideFooterPagination
                />
              </Box>
            </Await>
          </React.Suspense>
        </Paper>
      </Container>
    </>
  );
};
